import _ from 'lodash';
import { ConfigUtil } from 'wni-portals-util-js';

function setDefaultIncidentObj(type) {
    const res = {
        rowIdPath: ConfigUtil.getUuid()
    };
    _.unset(res, 'publicID');
    _.set(res, 'belongsToInsured_Ext', false);
    if(type === 'property') {
        _.set(res, 'location_Ext', {country: 'US'});
        _.set(res, 'driver', {});
    }
    if(type === 'vehicle') {
        _.set(res, 'vehicle', {});
        _.unset(res, 'belongsToInsured_Ext', false);
    }
    return res;
}

function removeContactsPrimaryAddress(contacts) {
    // bypass address dto validation for contact as it's not necessary to update address info on contact
    if (!_.isEmpty(contacts)) {
        contacts.forEach((contact) => {
            if (contact.primaryAddress) {
                _.unset(contact, 'primaryAddress');
            }
        })
    }
    return contacts;
}

export default {
    setDefaultIncidentObj,
    removeContactsPrimaryAddress
}