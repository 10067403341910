import React, { useState, useEffect, useContext, useCallback  } from "react"
import _ from "lodash";
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { useTranslator } from '@jutro/locale';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { useWniModal } from 'wni-components-platform-react';
import { useValidation } from '@xengage/gw-portals-validation-react';

import { GLIncidentsDetailService } from 'wni-capability-claim-gl';

import { messages as commonMessages } from '@xengage/gw-platform-translations';
import metadata from './GLInjuryIncidents.metadata.json5';
import messages from '../FNOLGLIncidentDetailsPage.messages';
import GLIncidentsUtil from "../utils/GLIncidentsUtil";
import GLInjuryDetail from "./InjuryDetail/GLInjuryDetail";
import styles from '../FNOLGLIncidentDetailsPage.module.scss';
import { Link } from '@jutro/router';

const GLInjuryIncidents = (props) => {
    const {
        claimVM,
        lobName,
        relatedContactsPath,
        relatedContacts,
        updateWizardData,
        updateWizardSnapshot,
    } = props;

    const {
        claimNumber,
    } = claimVM.value;

    const modalApi = useWniModal();
    const translator = useTranslator();
    const { authHeader } = useAuthentication();
    const { loadingMask: { setLoadingMask } } = useDependencies('loadingMask');
    const viewModelService = useContext(ViewModelServiceContext);
    const [currentRow, updateCurrentRowInteranl] = useState(null);
    const [selection, updateSelection] = useState([]);
    const [injuriedQuestionValue, updateInjuriedQuestionValue] = useState(false);
    const [injuryIncidentsData, updateInjuryIncidentsData] = useState([]);
    const [currentRowIndex, updateCurrentRowIndex] = useState(-1);
    const [bakSelectedIncident, setBakSelectedIncident] = useState({});

    const injuryIncidentsPath = `lobs.${lobName}.injuryIncidents_Ext`;
    const injuryIncidentsVM = _.get(claimVM, injuryIncidentsPath);
    const injuryIncidents = _.get(claimVM.value, injuryIncidentsPath, []);
    const relatedPersonContacts = relatedContacts.filter((contact) => ['UserContact', 'Person'].includes(contact.subtype));

    const {
        onValidate,
    } = useValidation('GLInjuryIncidents');
    const [showErrors, setShowErrors] = useState(false);

    useEffect(() => {
        updateInjuryIncidentsData(injuryIncidents);
        updateInjuriedQuestionValue(!_.isEmpty(injuryIncidents));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const updateClaim = (currentVM) => {
        const publicID = _.get(currentVM.value, 'publicID');
        const allInjuries = _.get(claimVM.value, injuryIncidentsPath);
        const currentIndex = allInjuries.findIndex((item) => item.publicID === publicID); 
        const newClaimVM = viewModelService.clone(claimVM);
        _.set(newClaimVM.value, `${injuryIncidentsPath}[${currentIndex}]`, currentVM.value);
        return newClaimVM;
    };

    const updateCurrentRow = (rowData, updateClaimData) => {
        if(!rowData) {
            updateCurrentRowInteranl(rowData);
            return false;
        }
        updateCurrentRowInteranl(rowData);
        if(updateClaimData) {
            const newClaimVM = updateClaim(rowData);
            updateClaimData(newClaimVM)
        }
    };

    const syncWizardData = (currentVM) => {
        updateCurrentRow(currentVM, updateWizardData);
    };

    const syncWizardDataSnapshot = (currentVM) => {
        updateCurrentRow(currentVM, updateWizardSnapshot);
    };

    const updateClaimVMForResponse = (res) => {
        _.set(claimVM.value, injuryIncidentsPath, res.injuryIncidents);
        _.set(claimVM.value, relatedContactsPath, res.relatedContacts);
        updateWizardSnapshot(claimVM);
        updateWizardData(claimVM);
        updateSelection([]);
        updateInjuryIncidentsData(res.injuryIncidents);
        updateCurrentRowIndex(-1);
    };

    const handleAddInjury = () => {
        const injuryObj = GLIncidentsUtil.setDefaultIncidentObj('injury');
        const {
            _dtoName,
            _xCenter,
        } = injuryIncidentsVM;
        const injuryVM = viewModelService.create(injuryObj, _xCenter, _dtoName);
        const addedInjury = injuryIncidentsVM.pushElement(injuryVM);
        updateCurrentRow(addedInjury);
        updateWizardData(claimVM);
        updateInjuryIncidentsData(injuryIncidentsVM.value);
        updateCurrentRowIndex(injuryIncidentsVM.length - 1);
    };

    const handleDeleteInjury = () => {
        modalApi.showConfirm({
            title: messages.removeIncidentTitle,
            message: messages.removeIncident,
            status: 'warning',
            icon: 'gw-error-outline'
        }).then(async(result) => {
            if (result === 'cancel' || result === 'close') {
                return _.noop();
            }
            syncWizardData(null);
            const selectedInjuryIncidents = [];
            selection.forEach((rowIndex) => {
                selectedInjuryIncidents.push(injuryIncidentsData[rowIndex]);
            })
            const injuryIncidentPublicIDs = selectedInjuryIncidents.map((item) => item.publicID);
            setLoadingMask(true);
            const res = await GLIncidentsDetailService.removeInjuryIncidents(
                claimNumber,
                injuryIncidentPublicIDs,
                authHeader
            );
            setLoadingMask(false);
            updateClaimVMForResponse(res);
            return true;
        }, _.noop);
    };

    const cancelInjury = () => {
        const incidentPublicID = _.get(currentRow, 'publicID.value');
        if (_.isEmpty(incidentPublicID)) {
            // new injury
            injuryIncidentsVM.value.splice(currentRowIndex, 1);
        } else {
            // edit injury
            injuryIncidentsVM.value.splice(currentRowIndex, 1, bakSelectedIncident);
        }
        updateInjuryIncidentsData(injuryIncidentsVM.value);
        updateCurrentRowInteranl(null);
        updateCurrentRowIndex(-1);
    };

    const editInjury = (item) => {
        const currentIndex = injuryIncidents.findIndex((incident) => incident.publicID === item.publicID);
        const childrenVM = _.get(claimVM, `${injuryIncidentsPath}.children`);
        const injuryVM = childrenVM.find((child) => child.value.publicID === item.publicID);       
        syncWizardData(injuryVM);
        updateCurrentRowIndex(currentIndex);
        setBakSelectedIncident(_.cloneDeep(injuryVM.value));
    };

    const saveAndContinue = () => {
        // update relatedContact
        const injury = _.get(currentRow.value, 'injuredContact');
        _.set(injury, 'updated_Ext', true);
        const isNewPerson = !relatedPersonContacts.some((contact) => contact.publicID === injury.publicID)
        const injuryIndex = relatedContacts.findIndex((contact) => contact.publicID === injury.publicID);
        let newInjuryPerson
        if(isNewPerson) {
            const newRelatedContact = {
                ...injury
            }
            newInjuryPerson = relatedContacts.concat([newRelatedContact]);
        } else {
            newInjuryPerson = relatedContacts.toSpliced(injuryIndex, 1, injury);
        }
        _.set(claimVM.value, relatedContactsPath, newInjuryPerson);

        setLoadingMask(true);
        GLIncidentsDetailService.updateOrAddInjuryIncident(
            claimNumber,
            currentRow.value,
            newInjuryPerson,
            authHeader
        )
        .then((res) => {
            updateClaimVMForResponse(res);
            setShowErrors(false);
            updateCurrentRow(null);
        })
        .catch(() => {
            modalApi.showAlert({
                title: messages.createDraftErrorTitle,
                message: translator(messages.createDraftErrorMessage),
                status: 'error',
                icon: 'gw-error-outline',
                confirmButtonText: commonMessages.ok
            }).catch(_.noop);
            return false;
        })
        .finally(() => {
            setLoadingMask(false);
        });
    };

    const onInjuryIncidentToggleChange = useCallback((newValue) => {
        if (newValue || injuryIncidentsData.length === 0) {
            updateInjuriedQuestionValue(newValue);
        }
    }, [injuryIncidentsData]);

    const renderNameCell =  (item) => {
        const firstName = _.get(item, 'injuredContact.firstName');
        const lastName = _.get(item, 'injuredContact.lastName', '');
        const name = _.isEmpty(firstName) ? lastName : `${firstName} ${lastName}`
        return name;
    };

    const renderEditCell =  (item) => {
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        return <Link className={styles.editCell} onClick={() => editInjury(item)}>{translator(messages.edit)}</Link>
    };

    const writeValue = (value, path) => {
        if(currentRow) {
            const newValue = _.clone(value);
            _.set(currentRow.value, path, newValue);
            syncWizardData(currentRow);
        }
    };

    const overrides = {
        '@field': {
            labelPosition: 'left',
        },
        glInjuryIncidentQuestion: {
            value: injuriedQuestionValue,
            onValueChange: onInjuryIncidentToggleChange
        },
        glInjuryIncidentsTableContainer: {
            visible: injuriedQuestionValue
        },
        glInjuryIncidentsTable: {
            data: injuryIncidentsData,
            selectedRows: selection,
            onSelectionChange: (rows) => updateSelection(rows)
        },
        deleteInjuries: {
            disabled: selection.length === 0
        },
        addInjury: {
            disabled: !_.isEmpty(currentRow)
        },
        injuryDetailContainer: {
            visible: !_.isEmpty(currentRow)
        },
        injuryDetails: {
            injuryVM: currentRow,
            relatedPersonContacts,
            onValueChange: writeValue,
            syncWizardData,
            syncWizardDataSnapshot,
            onDetailSave: saveAndContinue,
            onDetailCancel: cancelInjury
        }
    };
   
    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            renderNameCell,
            renderEditCell,
            handleDeleteInjury,
            handleAddInjury
        },
        resolveComponentMap: {
            injuryDetailComponent: GLInjuryDetail
        }
    };

    const readValue = (fieldId, fieldPath) => {
        return readViewModelValue(
            metadata.componentContent, claimVM, fieldId, fieldPath, overrides
        );
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={claimVM}
            overrideProps={overrides}
            onValueChange={writeValue}
            onValidationChange={onValidate}
            resolveValue={readValue}
            classNameMap={resolvers.resolveClassNameMap}
            callbackMap={resolvers.resolveCallbackMap}
            componentMap={resolvers.resolveComponentMap}
            showErrors={showErrors}
        />
    );
}

export default GLInjuryIncidents
