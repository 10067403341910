import React, { useEffect, useCallback } from 'react';
import _ from 'lodash';
import { useTranslator } from '@jutro/locale';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { fnolCommonMessages, ClaimUtil } from 'gw-capability-fnol-common-react';
import { WizardPage, wizardProps } from '@xengage/gw-portals-wizard-react';
import { WizardPageTemplate } from 'gw-portals-wizard-components-ui';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';

import metadata from './RepairFacilityPage.metadata.json5';

function FNOLPARepairFacilityPage(props) {
    const translator = useTranslator();
    const { wizardData: claimVM, updateWizardData } = props;
    const {
        isComponentValid,
        initialValidation,
        onValidate,
        registerInitialComponentValidation
    } = useValidation('RepairFacilityPage');

    const LOB_NAME = ClaimUtil.getLobByPolicyType(_.get(claimVM, 'value.policy.policyType', 'personalAuto'));

    useEffect(() => {
        if (_.isEmpty(_.get(claimVM, `value.lobs.${LOB_NAME}.repairOption.repairFacility`))) {
            const repairFacilityContact = claimVM.value.createContact({
                subtype: 'AutoRepairShop'
            });
            repairFacilityContact.initializePrimaryAddress();
            _.set(
                claimVM.value,
                `lobs.${LOB_NAME}.repairOption.repairFacility`,
                repairFacilityContact
            );
            updateWizardData(claimVM);
        }
    }, [LOB_NAME, claimVM, updateWizardData]);

    const overrideProps = {
        '@field': {
            labelPosition: 'left',
            showOptional: true,
            phoneWide: {
                labelPosition: 'top'
            }
        }
    };

    const shouldPageSkip = useCallback(() => {
        const repairOption = _.get(
            claimVM.value,
            `lobs.${LOB_NAME}.repairOption.repairFacility.contactName`
        );
        return !_.isEmpty(repairOption);
    }, [LOB_NAME, claimVM.value]);

    useEffect(() => {
        registerInitialComponentValidation(shouldPageSkip);
    }, [registerInitialComponentValidation, shouldPageSkip]);

    return (
        <WizardPage
            cancelLabel={translator(fnolCommonMessages.fnolSaveandExit)}
            skipWhen={initialValidation}
            disableNext={!isComponentValid}
            template={WizardPageTemplate}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={claimVM}
                overrideProps={overrideProps}
                onValidationChange={onValidate}
                onModelChange={updateWizardData}
            />
        </WizardPage>
    );
}

FNOLPARepairFacilityPage.propTypes = wizardProps;
export default FNOLPARepairFacilityPage;
