import { withAuthenticationContext } from '@xengage/gw-digital-auth-react';
import { wizardProps } from '@xengage/gw-portals-wizard-react';
import { FNOLPALossTypePage } from 'wni-capability-fnol-pa-react';
import PropTypes from 'prop-types';
import React from 'react';
import { withRouter } from 'react-router-dom';

function FNOLRTLossTypePage(props) {
    return <FNOLPALossTypePage {...props} />;
}

FNOLRTLossTypePage.propTypes = wizardProps;
FNOLRTLossTypePage.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func,
    }).isRequired,
};
export default withRouter(withAuthenticationContext(FNOLRTLossTypePage));
