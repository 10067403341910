import React, { useContext, useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import { WizardPage, wizardProps } from '@xengage/gw-portals-wizard-react';
import { WizardPageTemplate } from 'gw-portals-wizard-components-ui';
import {
    ViewModelServiceContext,
    withViewModelService,
    ViewModelForm
} from '@xengage/gw-portals-viewmodel-react';
import { useTranslator } from '@jutro/locale';
import { useModal } from '@jutro/components';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { Contact, Claim, fnolCommonMessages, saveFNOLDetails } from 'gw-capability-fnol-common-react';
import { messages as commonMessages } from '@xengage/gw-platform-translations';
import metadata from './InjuryPage.metadata.json5';
import styles from './InjuryPage.module.scss';
import messages from '../../FNOLWC.messages';

function WCInjuryPage(props) {
    const modalApi = useModal();
    const {
        wizardData: claimVM,
        updateWizardData,
        history: {
            location: { state }
        }
        
    } = props;
    const { authHeader } = useAuthentication();
    const translator = useTranslator();
    const viewModelService = useContext(ViewModelServiceContext);
    const {
        onValidate,
        isComponentValid,
        initialValidation,
        registerInitialComponentValidation,
        registerComponentValidation
    } = useValidation('WCInjuryPage');

    useEffect(
        () => {
            const nextClaimVM = viewModelService.create(
                new Claim(claimVM.value),
                'cc',
                'wni.edge.capabilities.claim.fnol.dto.WniFnolDTO',
                {
                    InjuryDetailsValidation: true
                }
            );
            updateWizardData(nextClaimVM);
        },
        // only execute once
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    const handleAddAnInjury = useCallback(() => {
        claimVM.lobs.workersComp.value.addBodyPartDetails();
        updateWizardData(claimVM);
    }, [claimVM, updateWizardData]);

    const handleRemoveAnInjury = useCallback(
        (event) => {
            const tabindex = parseInt(event.path.match(/[0-9]/gi), 10);
            const selectedObj = claimVM.lobs.workersComp.injuryIncident.bodyParts.value[tabindex];
            modalApi.showConfirm({
                title: messages.wcInjuryRemoveBodyInjury,
                message: translator(messages.wcInjuryModalRemoveBodyInjury, {
                    bodyPartDetail: tabindex + 1
                }),
                status: 'warning',
                icon: 'gw-error-outline',
                confirmButtonText: messages.wcInjuryRemoveButton,
                cancelButtonText: commonMessages.cancelMode
            }).then((result) => {
                if (result === 'cancel' || result === 'close') {
                    return _.noop();
                }
                claimVM.lobs.workersComp.value.removeBodyPartDetails(selectedObj);
                return updateWizardData(claimVM);
            }, _.noop);
        },
        [claimVM, modalApi, translator, updateWizardData]
    );

    const isVisibleInjuryBodyPartDescription = useCallback(
        (index) => {
            const bodyPartsPath = `lobs.workersComp.injuryIncident.bodyParts.children[${index}]`;
            const detailedBodyPartDescAvailableVM = _.get(claimVM, bodyPartsPath);
            return (
                detailedBodyPartDescAvailableVM !== undefined
                && !_.isEmpty(_.get(detailedBodyPartDescAvailableVM, 'detailedBodyPartDesc.aspects.availableValues'))
            );
        },
        [claimVM]
    );

    const isVisibleInjuryBodyPartSide = useCallback(
        (index) => {
            const bodyPartsSideOfBodyPath = `lobs.workersComp.injuryIncident.bodyParts.children[${index}].sideOfBody`;
            const bodyPartsSideOfBodyVM = _.get(claimVM, bodyPartsSideOfBodyPath);
            return bodyPartsSideOfBodyVM !== undefined && bodyPartsSideOfBodyVM.aspects.required;
        },
        [claimVM]
    );

    const onChangeInjuryBodyPart = useCallback(
        (value, index) => {
            const bodyPartPath = `lobs.workersComp.injuryIncident.bodyParts.children[${index}]`;
            _.set(claimVM, `${bodyPartPath}.sideOfBody`, undefined);
            _.set(claimVM, `${bodyPartPath}.detailedBodyPartDesc`, undefined);
            _.set(claimVM, `${bodyPartPath}.detailedBodyPart`, value);
            updateWizardData(claimVM);
        },
        [claimVM, updateWizardData]
    );

    const generateAddInjuryOverrides = useCallback(() => {
        const overrides = claimVM.lobs.workersComp.injuryIncident.bodyParts.children.map(
            (injured, index) => {
                return {
                    [`addInjuryTitleText${index}`]: {
                        value: translator(messages.wcInjuryBodyArea, {
                            bodyAreaCount: index + 1
                        })
                    },
                    [`removeInjuryTitleIcon${index}`]: {
                        visible:
                            claimVM.lobs.workersComp.injuryIncident.bodyParts.value.length !== 1
                    },
                    [`wcInjuryBodyPart${index}`]: {
                        onValueChange: (value) => {
                            onChangeInjuryBodyPart(value, index);
                        }
                    },
                    [`wcInjuryBodyPartDescription${index}`]: {
                        visible: isVisibleInjuryBodyPartDescription(index)
                    },
                    [`wcInjuryBodyPartSide${index}`]: {
                        visible: isVisibleInjuryBodyPartSide(index)
                    }
                };
            }
        );

        return Object.assign({}, ...overrides);
    }, [claimVM.lobs.workersComp.injuryIncident.bodyParts.children,
        claimVM.lobs.workersComp.injuryIncident.bodyParts.value.length,
        isVisibleInjuryBodyPartDescription, isVisibleInjuryBodyPartSide,
        onChangeInjuryBodyPart, translator]);

    const addDoctor = useCallback(() => {
        claimVM.lobs.workersComp.firstIntakeDoctor = new Contact({ subtype: 'Doctor' });
        updateWizardData(claimVM);
    }, [claimVM, updateWizardData]);

    const removeDoctor = useCallback(() => {
        claimVM.lobs.workersComp.firstIntakeDoctor = null;
        updateWizardData(claimVM);
    }, [claimVM, updateWizardData]);

    const handleMedicalAttention = useCallback(
        (value, path) => {
            if (value) {
                _.set(claimVM, path, value);
                updateWizardData(claimVM);
                return addDoctor();
            }
            _.set(claimVM, path, value);
            updateWizardData(claimVM);
            return removeDoctor();
        },
        [addDoctor, claimVM, removeDoctor, updateWizardData]
    );

    const overrideProps = {
        '@field': {
            labelPosition: 'left',
            showOptional: true,
            phoneWide: {
                labelPosition: 'top'
            }
        },
        wcInjuryMedicalAttentionRequired: {
            onValueChange: handleMedicalAttention
        },
        wcInjuryDoctorDetailsSection: {
            visible: !!_.get(claimVM.value, 'lobs.workersComp.medicalReport')
        },
        wcInjuryResultInDeath: {
            visible: !_.get(claimVM.value, 'lobs.workersComp.incidentReport')
        },
        ...generateAddInjuryOverrides()
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            handleAddAnInjury: handleAddAnInjury,
            handleRemoveAnInjury: handleRemoveAnInjury,
            handleMedicalAttention: handleMedicalAttention
        }
    };

    const { claimStatus } = state || {};

    const validateClaimStatus = useCallback(() => {
        return (
            !_.isNil(claimStatus)
            && !!_.get(claimVM, 'lossLocation.city.value')
            && claimStatus === translator({ id: 'typekey.ClaimState.draft', defaultMessage: 'Draft' })
        );
    }, [claimStatus, claimVM, translator]);

    const validateInjuryCheck = useCallback(() => {
        return claimVM.aspects.valid && claimVM.aspects.subtreeValid;
    }, [claimVM]);

    useEffect(() => {
        registerInitialComponentValidation(validateClaimStatus);
    }, [registerInitialComponentValidation, validateClaimStatus]);

    useEffect(() => {
        registerComponentValidation(validateInjuryCheck);
    }, [registerComponentValidation, validateInjuryCheck]);

    const onNext = useCallback(async () => {
        claimVM.value = await saveFNOLDetails(claimVM.value, authHeader)
        return claimVM
    }, [authHeader, claimVM]);

    return (
        <WizardPage
            cancelLabel={translator(fnolCommonMessages.fnolSaveandExit)}
            skipWhen={initialValidation}
            disableNext={!isComponentValid}
            template={WizardPageTemplate}
            onNext={onNext}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={claimVM}
                overrideProps={overrideProps}
                onModelChange={updateWizardData}
                onValidationChange={onValidate}
                classNameMap={resolvers.resolveClassNameMap}
                callbackMap={resolvers.resolveCallbackMap}
            />
        </WizardPage>
    );
}

WCInjuryPage.propTypes = {
    viewModelService: PropTypes.shape({
        create: PropTypes.func
    }).isRequired
};

WCInjuryPage.propTypes = wizardProps;
export default withRouter(withViewModelService(WCInjuryPage));
