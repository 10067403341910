import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { ModalNext, ModalBody, ModalFooter, Button } from '@jutro/components';
import { WniTableRowUtil } from 'wni-portals-util-react';
import { DisplayColumn } from '@jutro/legacy/datatable';
import { Flex, FlexItem } from '@jutro/layout';
import { WniDataTable } from 'wni-common-base-components';
import { CPRisksService } from 'wni-capability-quoteandbind-cp';

function BuildingAndOccupanyInfoPopup(props) {
    // note: import not set legacy for Kufri update, save close button
    const {
        size,
        isOpen,
        onResolve,
        onReject,
        setLoadingMask,
        lossCostResponses,
        jobID,
        sessionUUID,
        authHeader,
        locationPublicID,
        buildingPublicID
    } = props;

    const [selectedBuilding, updateSelectedBuilding] = useState(null);
    const [selectedOccupancy, updateSelectedOccupancy] = useState(null);
    
    const handleSaveClose = async() => {
        setLoadingMask(true);
        await CPRisksService.saveSelectedBuildingOccupancyInfo(jobID, sessionUUID, locationPublicID, buildingPublicID, selectedBuilding, selectedOccupancy, authHeader);
        setLoadingMask(false);
        onResolve();
    }
    return (
        <ModalNext isOpen={isOpen} className={size}>
            <ModalBody id="searchClassCodeDescription" autoFocus={false}>
                <Flex justifyContent="between">
                    <FlexItem><h3>Building And Occupancy Description</h3></FlexItem>
                    <FlexItem><Button className="btn-link" label='Return to New Building' onClick={() => onReject()}/></FlexItem>
                </Flex>
                <hr />
                {
                    lossCostResponses.map(lossCostResponse => {
                        return <div className='table-mutiple'>
                            {/* Building */}
                            <WniDataTable
                                columnsConfigurable={false}
                                data={[_.get(lossCostResponse, 'buildingInfo')]}
                                id="buildingInfo"
                                showPagination={false}
                                showSearch={false}
                            >
                                <DisplayColumn
                                    id="select"
                                    textAlign="left"
                                    columnProportion="3"
                                    renderCell={(item) => {
                                        const buttonDom = <Button className="btn-link" label='Select' onClick={() => updateSelectedBuilding(item)} disabled={_.isEqual(selectedBuilding, item)}/>
                                        return WniTableRowUtil.renderCell(item.descriptionName, buttonDom)
                                    }}
                                    sortable={false}
                                />
                                <DisplayColumn
                                    header="Building Description"
                                    id="Name"
                                    path="descriptionName"
                                    textAlign="left"
                                    columnProportion="3"
                                />
                                <DisplayColumn
                                    header="CSP Class Code"
                                    id="CspClass"
                                    path="cspClass"
                                    textAlign="left"
                                    columnProportion="3"
                                />
                                <DisplayColumn
                                    header="BG I Specific"
                                    id="BGILossCost"
                                    path="bgilossCost"
                                    textAlign="left"
                                    columnProportion="3"
                                />
                                <DisplayColumn
                                    header="Construction Type To Use"
                                    id="ConstructionType"
                                    path="constructionType"
                                    textAlign="left"
                                    columnProportion="3"
                                />
                            </WniDataTable>
                            {/* Occupancy */}
                            <WniDataTable
                                columnsConfigurable={false}
                                data={_.get(lossCostResponse, 'occupanciesInfo', [])}
                               
                                id="occupancyInfo"
                                showPagination={false}
                                showSearch={false}
                            >
                                <DisplayColumn
                                    id="emptyPlaceHolder"
                                    textAlign="left"
                                    columnProportion="3"
                                />
                                <DisplayColumn
                                    id="select"
                                    textAlign="left"
                                    columnProportion="3"
                                    renderCell={(item) => {
                                        const buttonDom = <Button className="btn-link" label='Select' onClick={() => updateSelectedOccupancy(item)} disabled={_.isEqual(selectedOccupancy, item)}/>
                                        return WniTableRowUtil.renderCell(item.descriptionName, buttonDom)
                                    }}
                                    sortable={false}
                                />
                                <DisplayColumn
                                    header="Occupancy Description"
                                    id="name"
                                    path="descriptionName"
                                    textAlign="left"
                                    columnProportion="3"
                                    sortable
                                />
                                <DisplayColumn
                                    header="CSP Class Code"
                                    id="CspClass"
                                    path="cspClass"
                                    textAlign="left"
                                    columnProportion="3"
                                    sortable
                                />
                                <DisplayColumn
                                    header="BG I Specific"
                                    id="BGILossCost"
                                    path="bgilossCost"
                                    textAlign="left"
                                    columnProportion="3"
                                    sortable
                                />
                            </WniDataTable>
                        </div>
                    })
                }
            </ModalBody>
            <ModalFooter>
                <Button label='Save & Close' onClick={()=>{handleSaveClose()}} disabled={_.isNil(selectedBuilding) || _.isNil(selectedOccupancy)}/>
            </ModalFooter>
        </ModalNext>
    );
}
BuildingAndOccupanyInfoPopup.propTypes = {
    size: PropTypes.string,
    isOpen: PropTypes.bool,
    onReject: PropTypes.func,
    onResolve: PropTypes.func,
    lossCostResponses: PropTypes.array
};

BuildingAndOccupanyInfoPopup.defaultProps = {
    size: 'lg',
    isOpen: false,
    onResolve: _.noop,
    onReject: _.noop,
    lossCostResponses: []
};

export default BuildingAndOccupanyInfoPopup;
