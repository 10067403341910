import _ from 'lodash';
import { CountryUtil } from "wni-common-sources";
 
function zipcodeFormat(code) {
    return CountryUtil.postalCodeFormat('US', code)
}
 
function zipCodeFormatForCanada(code) {
    return CountryUtil.postalCodeFormat('CA', code)
}
 
function isZipCodeMatchPattern(country, value) {
    return CountryUtil.isPostalCodeValid(country, value);
};

function dateFormat(value) {
    if (!value) {
        return value;
    }
    return value.replace(/(\d{2})(\d{2})(\d{4})/, '$1/$2/$3')
}

function placeholderFormatMap(vm, fieldsMap) {
    const overrides = fieldsMap.map((item) => {
        const { id, path } = item;
        const required = _.get(vm, `${path}.aspects.required`);
        return {
            [id]: {
                placeholder: required ? '-- Required for Quote --' : ''
            }
        };
    });
    return overrides;
}
function placeholderFormat(vm, path, customRequired = false, requiredForIssuance = false) {
    const required = _.get(vm, `${path}.aspects.required`);
    if (customRequired) {
        if (requiredForIssuance) {
            return {
                placeholder: '-- Required for Issuance --'
            };
        }
        return {
            placeholder: '-- Required for Quote --'
        };
    }
    return {
        placeholder: required ? '-- Required for Quote --' : ''
    };
}

function directionStrFormat(value) {
    const directionsMap = ['ne', 'nw', 'sw', 'se', 'n', 's', 'w', 'e'];
    const valueStrMap = value.split(/ /);
    valueStrMap.forEach((v, i) => {
        directionsMap.forEach((d) => {
            if (v.toLowerCase() === d || v.toLowerCase() === `${d},` || v.toLowerCase() === `,${d}`) {
                valueStrMap[i] = v.toUpperCase();
            }
        });
    });
    return valueStrMap.join(' ');
}

// check the valus is changed for onBlur event
const isValueChanged = (valueObject) => {
    if (!valueObject) {
        return true;
    }
    // value not changed and not call the service
    if (_.get(valueObject, 'value') === _.get(valueObject, 'beforeValue')) {
        return false;
    }
    return true;
};

export default {
    zipcodeFormat,
    zipCodeFormatForCanada,
    isZipCodeMatchPattern,
    dateFormat,
    placeholderFormatMap,
    placeholderFormat,
    directionStrFormat,
    isValueChanged
};
