import _ from 'lodash';
import FlowStepManager from './FlowStepManager';
import ServiceErrorUtil from '../ServiceErrorUtil';
import UWIssueUtil from './UWIssueUtil';
import ValidationIssueUtil from './ValidationIssueUtil';

const {
    // DEFAULT_FRIENDLY_MESSAGE,
    prependWithFriendMessage,
} = ServiceErrorUtil;

const {
    hasBlockingOrRejectedUWIssue,
    isUwIssuePendingApproval,
    quoteDetailsSummaryPageHasBlockingUWIssue,
    filterUWIssuesBasedOnPeriod,
    isBlockingOrRejectedUWIssue,
} = UWIssueUtil;


const {
    convertPcDisplayMessageToIssues,
    getValidationIssues,
    hasValidationIssue,
    hasValidationError,
    hasNewValidationIssue,
    getValidationIssueKey,
    getServerIssues,
} = ValidationIssueUtil;


// /**
//  * Receives an instance of PCDisplayMessageDTO and returns a list
//  * of validation issues;
//  * @param {object} pcDisplayMessage instance of PCDisplayMessageDTO;
//  * @returns {array} an array of [{type, reason}]
//  */
// function convertPcDisplayMessageToIssues(pcDisplayMessage = {}) {
//     const {
//         errors: displayErrors = [],
//         warnings: displayWarnings = [],
//     } = pcDisplayMessage;

//     const errors = displayErrors.map((errorString) => {
//         return {
//             type: 'error',
//             reason: errorString
//         };
//     });
//     const warnings = displayWarnings.map((warningString) => {
//         return {
//             type: 'warning',
//             reason: warningString
//         };
//     });
//     return errors.concat(warnings);
// }


/**
 * Filter Eligibility issues based on periodPublicID
 * @param {array} eligibilityIssues array of ILobEligibilityDetailDTO
 * @param {array} periodPublicID
 * @returns {array} ILobEligibilityDetailDTO
 */
function filterEligibilityIssuesBasedOnPeriod(eligibilityIssues, periodPublicID) {
    if (_.isEmpty(eligibilityIssues) || _.isEmpty(periodPublicID)) {
        return [];
    }
    const retval = eligibilityIssues
        .filter((eligibilityIssue) => periodPublicID === eligibilityIssue.periodPublicID)
    return retval;
}


/**
 * Checks whether there is any validation error that is on or before QuotPage.
 * i.e. Ignore errors on PaymentPage.
 * @param {object} errorsAndWarnings
 * @returns {boolean}
 */
function hasValidationErrorOnQuotePage(errorsAndWarnings) {
    return hasValidationIssue(errorsAndWarnings, (issue) => {
        const {
            flowStepId,
            type,
        } = issue;
        // See PortlaValidationIssue.getPortalPageFlowStepId()
        const retval = type === 'error' && flowStepId !== FlowStepManager.PORTAL_FLOW_STEP_PA_PAYMENT_PAGE_ID;
        return retval;
    });
}


/**
 * A page jump function
 * @param {function} wizardJumpFn
 * @param {number} pageIndex
 * @returns {function} a page jump function
 */
const getPageJumpFn = (wizardJumpFn, pageIndex) => {
    return () => wizardJumpFn(pageIndex);
};

/**
 * Receives two listss:
 * validationIssues: [{flowStepId}]
 * wizardSteps: [{id}]
 *
 * And a Wizard jump function extracted from useWizardSteps();
 *
 * Returns a map that has the shape of {flowStepId: jumpFun}
 * @param {array} validationIssues
 * @param {array} wizardSteps
 * @param {object} wizardStepToFieldMapping
 * @param {function} wizardJumpFn
 * @returns {object} a map of pageName to the jump function
 */
function getValidationIssueJumpFnMap(validationIssues, wizardSteps,
    wizardStepToFieldMapping, wizardJumpFn) {
    const flowStepToPageMap = FlowStepManager.getFlowStepToPageMap(wizardStepToFieldMapping);
    const retval = {};
    validationIssues.forEach((validationIssue) => {
        const pageId = FlowStepManager.getPageIdForValidationIssue(validationIssue, flowStepToPageMap);
        if (!pageId) {
            return;
        }
        const wizardPageIndex = wizardSteps.findIndex((step) => step.id === pageId);
        if (wizardPageIndex >= 0) {
            const issueKey = getValidationIssueKey(validationIssue);
            retval[issueKey] = getPageJumpFn(wizardJumpFn, wizardPageIndex);
        }
    });
    return retval;
}

/**
 * Get page jump function to earliest page that has validation error
 * @param {array} validationIssues
 * @param {array} wizardSteps
 * @param {object} wizardStepToFieldMapping
 * @param {function} wizardJumpFn
 * @returns {function} a page jump func, or null if no page could be found
 */
function getJumpFnToEarliestPageWithIssue(validationIssues, wizardSteps,
    wizardStepToFieldMapping, wizardJumpFn) {
    const flowStepToPageMap = FlowStepManager.getFlowStepToPageMap(wizardStepToFieldMapping);

    const pageIndices = [];
    validationIssues.forEach((validationIssue) => {
        const pageId = FlowStepManager.getPageIdForValidationIssue(validationIssue, flowStepToPageMap);
        if (!pageId) {
            return;
        }
        const wizardPageIndex = wizardSteps.findIndex((step) => step.id === pageId);
        if (wizardPageIndex >= 0) {
            pageIndices.push(wizardPageIndex);
        }
    });

    let retval = null;
    if (!_.isEmpty(pageIndices)) {
        const earliestPage = _.min(pageIndices);
        retval = getPageJumpFn(wizardJumpFn, earliestPage);
    }
    return retval;
}

/**
 * Populate wizard page level issues with content from validationIssues
 * @param {array} validationIssues 
 * @param {object} wizardStepToFieldMapping
 * @returns {object}
 */
function populateWizardPageLevelIssues(validationIssues, wizardStepToFieldMapping) {
    const pageIdToIssuesMap = FlowStepManager.getPageIdToValidationIssuesMap(validationIssues, wizardStepToFieldMapping);
    // Object.entries(pageIdToIssuesMap).forEach(([pageId, pageLevelIssues]) => {
    //     updateWizardPageStickyIssues(pageId, pageLevelIssues);
    // });
    return pageIdToIssuesMap;
}

/**
 * Get function to retrieve Issue sort key based on its flowStepID
 *
 * See Also:
 * 1, #getValidationIssueJumpFnMap()
 * 2, ValidationIssuesComponent#getSortKeyForIssueWithSameType
 * @param {array} wizardSteps content from pa-wizard-config.json5
 * @param {array} wizardStepToFieldMapping content from pa-wizard-step-to-field-mapping.json5
 * @returns {function}
 */
function getValidationIssueSortByFlowStepFunc(wizardSteps, wizardStepToFieldMapping) {
    const flowStepToPageMap = FlowStepManager.getFlowStepToPageMap(wizardStepToFieldMapping);
    const MAX_INDEX = wizardSteps.length;
    return (validationIssue) => {
        const pageId = FlowStepManager.getPageIdForValidationIssue(validationIssue, flowStepToPageMap);
        let retval = MAX_INDEX;
        if (pageId) {
            const wizardPageIndex = wizardSteps.findIndex((step) => step.id === pageId);
            if (wizardPageIndex >= 0) {
                retval = wizardPageIndex;
            }
        }
        return retval;
    };
}

/**
 * Try to filter out valiation issues for current page
 *
 * USAGE EXAMPLE:
 * const quoteErrorsAndWarnings = _.get(wizardPageData, WizardConstants.quoteErrorsAndWarnings);
 * if (quoteErrorsAndWarnings) {
 *     const valiationIssues = ErrorsAndWarningsUtil.getValidationIssues(quoteErrorsAndWarnings);
 *     const errorsForCurrentPage = ErrorsAndWarningsUtil.filterIssuesForCurrentPage(
 *         valiationIssues, wizardStepToFieldMapping, 'error', 'PAPolicyDetailsPage');
 *     updateValidationIssuesWarning(errorsForCurrentPage);
 * }
 *
 * @param {array} validationIssues and array of valiation issues, typically retrieved through
 *                ErrorsAndWarningsUtil.getValidationIssues()
 * @param {object} wizardStepToFieldMapping  from pa-wizard-config.json
 * @param {string} issueType 'warning' or 'error'
 * @param {string} currentPageId  current pageId, as defined in pa-wizard-conifig.json
 * @returns {array} the validation issues for current page. Could be empty.
 */
function filterIssuesForCurrentPage(validationIssues, wizardStepToFieldMapping,
    issueType, currentPageId = 'UnknownPage') {
    if (_.isEmpty(validationIssues)) {
        return [];
    }
    const issuesWithType = validationIssues.filter((issue) => issue.type === issueType);
    if (_.isEmpty(issuesWithType)) {
        return [];
    }
    const flowStepToPageMap = FlowStepManager.getFlowStepToPageMap(wizardStepToFieldMapping);
    const issuesGroupedByPage = _.groupBy(issuesWithType, (validationIssue) => {
        const pageId = FlowStepManager.getPageIdForValidationIssue(validationIssue, flowStepToPageMap);
        return pageId || 'UnknownPage';
    });
    return _.get(issuesGroupedByPage, currentPageId, []);
}

function getPCDisplayIssues(submission) {
    const message1 = _.get(submission, 'errorsAndWarnings.pcDisplayMessage_Ext.warnings', []);
    // policy change
    const message2 = _.get(submission, 'errorsAndWarnings_Ext.pcDisplayMessage_Ext.warnings', []);
    return message1.concat(message2).map((warning) => {
        return {
            reason: warning,
            type: 'warning'
        };
    });
}


/**
 * Extrat service error and return a list of validation issues
 * @param {object} errorsAndWarnings
 * @returns {array}
 */
function getServerErrorsAsValidationIssues(errorsAndWarnings = {}) {
    const serviceErrors = _.get(errorsAndWarnings, 'serverErrors_Ext');
    if (_.isEmpty(serviceErrors)) {
        return null;
    }
    const retval = serviceErrors.map((serviceError) => {
        return {
            type: 'error',
            reason: serviceError,
        };
    });
    return retval;
}


/**
 * Same with hasUnApprovedIneligibleUWIssue(), but for R2 and later Lobs.
 * 
 * @deprecated use UWIssueUtil.hasUnApprovedUWIssueByTypeCode() instead
 * @param {array} uwIssues 
 * @param {array} ineligibleUWIssueCodeList 
 * @returns {boolean}
 */
function hasUnApprovedIneligibleUWIssue(uwIssues = [], ineligibleUWIssueCodeList = []) {
    if (_.isEmpty(uwIssues)) {
        return false;
    }
    const retval = uwIssues.some((uwIssue) => {
        const isIneligibleIssue = ineligibleUWIssueCodeList.includes(uwIssue.issueTypeCode_Ext);
        if (isIneligibleIssue) {
            const isPendingApproval = isUwIssuePendingApproval(uwIssue);
            return isPendingApproval;
        }
        return false;
    });
    return retval;
}

// /**
//  * Checks whether there is unapproved ineligibility UW issue,
//  * i.e. issueType.code is PAIneligibleSelect_Ext or PAIneligibleSelectSignature_Ext
//  * @param {array} uwIssues an array of UWIssueDTO
//  * @param {string} policyType 'Select' or 'Signature'
//  * @returns {boolean} whether there is pending unapproved ineligible UW Issue
//  */
// function hasUnApprovedIneligibleUWIssueForPA(uwIssues = []) { // , policyType = '') {
//     // if (_.isEmpty(uwIssues) || _.isEmpty(policyType)) {
//     //     return false;
//     // }
//     const retval = hasUnApprovedIneligibleUWIssue(uwIssues, INELIGIBLE_UW_ISSUE_CODES);
//     return retval;
// }

/**
 * Check whether the current validation issue comes after QuotePage
 * @param {object} validationIssue
 * @returns {boolean}
 */
function isIssueOnOrBeforeQuotPage(validationIssue = {}) {
    const {
        flowStepId,
        type,
    } = validationIssue;
    const isAfterQuotePage = flowStepId === FlowStepManager.PORTAL_FLOW_STEP_PA_PAYMENT_PAGE_ID;
    return !isAfterQuotePage;
}

/**
 * Check whether the current validation issue comes from DriverPage
 * @param {object} validationIssue
 * @returns {boolean}
 */
function isIssueOnDriverPage(validationIssue = {}) {
    const {
        flowStepId
    } = validationIssue;
    return flowStepId === FlowStepManager.PORTAL_FLOW_STEP_PA_DRIVER_PAGE_ID;
}

/**
 * Extract serverErrors from DTO
 * @param {object} jobDTO QuoteDataDTO or PolicyChangeDataDTO
 * @param {string} serverErrorPath
 * @returns {array} an array of validation issues
 */
function extractServerErrors(jobDTO = {}, serverErrorPath = 'errorsAndWarnings.serverErrors_Ext') {
    const serverErrors = _.get(jobDTO, serverErrorPath, []);
    const retval = serverErrors.map((serverError) => {
        const formattedErrorMsg = prependWithFriendMessage(serverError);
        return {
            reason: formattedErrorMsg,
            type: 'error',
        };
    });
    // const retval = [];
    // 
    // if (!_.isEmpty(serverErrors)) {
    //     retval.push({
    //         type: 'error',
    //         // reason: DEFAULT_FRIENDLY_MESSAGE,
    //         reason: formattedErrorMsg
    //     });
    // }
    return retval;
}



/**
 * Checks whether there is ineligibility UW issue,
 * i.e. issueType.code is PAIneligibleSelect_Ext or PAIneligibleSelectSignature_Ext
 * @param {array} uwIssues an array of UWIssueDTO
 * @param {array} ineligibleUWIssueCodeList the ineligible UW Issue or undefined for empty
 * @returns {boolean}
 * @deprecated Consider to move it to EligibilityIssueUtil
 */
function hasIneligibleUWIssue(uwIssues = [], ineligibleUWIssueCodeList = []) {
    if (_.isEmpty(uwIssues)) {
        return false;
    }
    
    const isIneligibleIssue = uwIssues.some((uwIssue) => {
        const retval = ineligibleUWIssueCodeList.includes(uwIssue.issueTypeCode_Ext)
        return retval;
    });
    return isIneligibleIssue;
}

/**
 * Check whether InEligibility issue has been triggered for periods
 * @param {array} sxsPeriods  SxsPeriodDTO list
 * @param {array} uwIssueList UWIssueDTO array
 * @returns {object} map of periodPublicID to hasInEligibility
 * @deprecated Consider to move it to EligibilityIssueUtil
 */
function getPeriodsIneligibleStatusMap(sxsPeriods, uwIssueList) {
    const retval = {};
    sxsPeriods.forEach((period) => {
        const periodPublicID = period.publicID;
        const sxsPeriodUWIssues = filterUWIssuesBasedOnPeriod(uwIssueList, periodPublicID);
        const hasInEligibilityIssue = hasIneligibleUWIssue(sxsPeriodUWIssues);
        retval[periodPublicID] = hasInEligibilityIssue;
    });
    return retval;
}

/**
 * Indicates if a given set of eligibility rules contains an eligibility issue that should block referral to UW
 */
function isReferralBlockedByEligibility(eligibilityIssues = []) {
    var retval = false

    eligibilityIssues.forEach((eligibilityIssue) => {
        if(eligibilityIssue.blocksReferral) {
            retval = true
        }
    })
    return retval
}

function filterBlockingUWIssuesBasedOnPeriodFromEW(errorsAndWarnings, periodPublicID) {
    const uwIssues = _.get(errorsAndWarnings, 'underwritingIssues', []);
    const retval = UWIssueUtil.filterBlockingUWIssuesBasedOnPeriod(uwIssues, periodPublicID);
    return retval;
}

function filterCPPreQuoteUWIs(sideBySideData) {
    const underwritingIssues = _.get(sideBySideData, 'errorsAndWarnings.underwritingIssues', []);
    const codeOfUWIsNotshowOnPE = ['CP7CheckTIVInRange1_Ext', 'CP7CheckTIVInRange2_Ext', 'CP7CheckTIVInRange3_Ext', 'CP7CheckTIVInRange4_Ext'];
    const filteredErrorsAndWarnings = _.filter(underwritingIssues, (issue) => {
        return !_.includes(codeOfUWIsNotshowOnPE, issue.issueTypeCode_Ext)
    });
    return filteredErrorsAndWarnings;
}

export default {
    // ========================================
    // UWIssue methods have been extracted into UWIssueutil
    // hasBlockingUWIssue,
    // isBlockingUWIssue,
    hasBlockingOrRejectedUWIssue,
    isUwIssuePendingApproval,
    quoteDetailsSummaryPageHasBlockingUWIssue,
    filterUWIssuesBasedOnPeriod,
    isBlockingOrRejectedUWIssue,
    //
    filterBlockingUWIssuesBasedOnPeriodFromEW,
    // ========================================
    // ValidationIssue methods have been extracted into ValidationIssueUtil
    convertPcDisplayMessageToIssues,
    getValidationIssues,
    hasValidationIssue,
    hasValidationError,
    hasNewValidationIssue,
    getValidationIssueKey,
    // =======================================
    // Validation Issue methods involving FlowStepManager
    getValidationIssueJumpFnMap,
    // Validation Issue to page filter and jump
    hasValidationErrorOnQuotePage,
    // getFlowStepToPageMap,
    getJumpFnToEarliestPageWithIssue,
    getValidationIssueSortByFlowStepFunc,
    // filterIssuesForCurrentPage,
    isIssueOnOrBeforeQuotPage,
    isIssueOnDriverPage,
    //
    populateWizardPageLevelIssues,
    // ========================================
    // Ineligiblity Issue methods
    filterEligibilityIssuesBasedOnPeriod,
    hasUnApprovedIneligibleUWIssue,
    isReferralBlockedByEligibility,
    // hasUnApprovedIneligibleUWIssueForPA,

    // hasIneligibleUWIssue,
    // getPeriodsIneligibleStatusMap,
    // ========================================
    // Server Error and Display Messages
    getPCDisplayIssues,
    getServerErrorsAsValidationIssues,
    // convertPcDisplayMessageToIssues,
    extractServerErrors,
    // ========================================
    getServerIssues,
    filterCPPreQuoteUWIs,
};
