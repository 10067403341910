import { defineMessages } from 'react-intl';

export default defineMessages({
    contactPerson: {
        id: 'wni.pe.fnol.pa.views.incidentdetails.singlePedestrianPopup.injuredPerson',
        defaultMessage: 'Person'
    },
    addNewPerson: {
        id: 'wni.pe.fnol.pa.views.incidentdetails.singlePedestrianPopup.addNewPerson',
        defaultMessage: 'Add new person'
    },
    injuredPersonFirstName: {
        id: 'wni.pe.fnol.pa.views.incidentdetails.singlePedestrianPopup.PedestrianFirstName',
        defaultMessage: 'Pedestrian First Name'
    },
    injuredPersonLastName: {
        id: 'wni.pe.fnol.pa.views.incidentdetails.singlePedestrianPopup.PedestrianLastName',
        defaultMessage: 'Pedestrian Last Name'
    },
    cancel: {
        id: 'wni.pe.fnol.pa.views.incidentdetails.singlePedestrianPopup.cancel',
        defaultMessage: 'Cancel'
    },
    save: {
        id: 'wni.pe.fnol.pa.views.incidentdetails.singlePedestrianPopup.save',
        defaultMessage: 'Save'
    }
})