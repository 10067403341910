import { setComponentMapOverrides } from '@jutro/legacy/uiconfig';

import FNOLPAWizard from './FNOLPAWizard.pe';
import FNOLPAVehiclesPage from './pages/Vehicles/FNOLPAVehiclesPage.pe';
import FNOLPATypeOfIncidentPage from './pages/TypeOfIncident/TypeOfIncidentPage.pe';
import FNOLPATypeOfRepairPage from './pages/TypeOfRepair/TypeOfRepairPage.pe';
import FNOLPARepairFacilityPage from './pages/RepairFacility/RepairFacilityPage.pe';
import FNOLPACollisionDetailsPage from './pages/Details/Collision/CollisionDetailsPage.pe';
import FNOLPAGlassDetailsPage from './pages/Details/Glass/GlassDetailsPage.pe';
import FNOLPATheftDetailsPage from './pages/Details/Theft/TheftDetailsPage.pe';
import FNOLPARepairFacilityMapPage from './pages/RepairFacilityMap/RepairFacilityMapPage.pe';
import FNOLPALossTypePage from './pages/LossType/FNOLPALossTypePage.pe';
import FNOLPAIncidentsPage from './pages/Incidents/FNOLPAIncidentsPage.pe';

setComponentMapOverrides(
    {
        FNOLPAWizard: { component: 'FNOLPAWizard' },
        FNOLPATypeOfIncidentPage: { component: 'FNOLPATypeOfIncidentPage' },
        FNOLPAVehiclesPage: { component: 'FNOLPAVehiclesPage' },
        FNOLPATypeOfRepairPage: { component: 'FNOLPATypeOfRepairPage' },
        FNOLPARepairFacilityPage: { component: 'FNOLPARepairFacilityPage' },
        FNOLPACollisionDetailsPage: { component: 'FNOLPACollisionDetailsPage' },
        FNOLPAGlassDetailsPage: { component: 'FNOLPAGlassDetailsPage' },
        FNOLPATheftDetailsPage: { component: 'FNOLPATheftDetailsPage' },
        FNOLPARepairFacilityMapPage: { component: 'FNOLPARepairFacilityMapPage' },
        FNOLPALossTypePage: { component: 'FNOLPALossTypePage' },
        FNOLPAIncidentsPage: { component: 'FNOLPAIncidentsPage' }
    },
    {
        FNOLPAWizard,
        FNOLPATypeOfIncidentPage,
        FNOLPAVehiclesPage,
        FNOLPATypeOfRepairPage,
        FNOLPARepairFacilityPage,
        FNOLPACollisionDetailsPage,
        FNOLPAGlassDetailsPage,
        FNOLPATheftDetailsPage,
        FNOLPARepairFacilityMapPage,
        FNOLPALossTypePage,
        FNOLPAIncidentsPage
    }
);

export {
    FNOLPAWizard as default,
    FNOLPATypeOfIncidentPage,
    FNOLPAVehiclesPage,
    FNOLPATypeOfRepairPage,
    FNOLPARepairFacilityPage,
    FNOLPACollisionDetailsPage,
    FNOLPAGlassDetailsPage,
    FNOLPATheftDetailsPage,
    FNOLPARepairFacilityMapPage,
    FNOLPALossTypePage,
    FNOLPAIncidentsPage
};
