import { defineMessages } from 'react-intl';

export default defineMessages({
    whatTitle: {
        id: 'pe.fnol.wizard.step.wc.What',
        defaultMessage: 'What'
    },
    lossType: {
        id: 'pe.fnol.wizard.step.wc.Loss Type',
        defaultMessage: 'Loss Type'
    },
    wcWhereTitle: {
        id: 'pe.fnol.wizard.step.wc.Where',
        defaultMessage: 'Where'
    },
    wcInjuryTitle: {
        id: 'pe.fnol.wizard.step.wc.Injury',
        defaultMessage: 'Injury'
    },
    wcInjuryDetails: {
        id: 'pe.fnol.wc.views.injury.Injury Details',
        defaultMessage: 'Injury Details'
    },
    wcSourceDetail: {
        id: 'pe.fnol.wc.views.injury.Source Detail',
        defaultMessage: 'Injury Cause/Source Detail'
    },
    wcInjuryType: {
        id: 'pe.fnol.wc.views.injury.Injury Type',
        defaultMessage: 'Injury Type'
    },
    wcInjuryTypeDetails: {
        id: 'pe.fnol.wc.views.injury.Injury Type Details',
        defaultMessage: 'Injury Type Details'
    },
    wcSelectInjuryType: {
        id: 'pe.fnol.wc.views.injury.Select injury type',
        defaultMessage: '-- Select injury type --'
    },
    wcInjuryDescription: {
        id: 'pe.fnol.wc.views.injury.Injury Description',
        defaultMessage: 'Injury Description'
    },
    wcInjuryResultDeath: {
        id: 'pe.fnol.wc.views.injury.Injury/illness result in death?',
        defaultMessage: 'Injury/illness result in death?'
    },
    wcInjuryWorkTimeLost: {
        id: 'pe.fnol.wc.views.injury.Lost time from work?',
        defaultMessage: 'Lost time from work?'
    },
    wcInjuryMedicalTreatment: {
        id: 'pe.fnol.wc.views.injury.Medical Treatment',
        defaultMessage: 'Medical Treatment'
    },
    wcInjuryMedicalAttentionRequired: {
        id: 'pe.fnol.wc.views.injury.Medical attention required?',
        defaultMessage: 'Medical attention required?'
    },
    wcInjuryExaminationDate: {
        id: 'pe.fnol.wc.views.injury.Examination Date',
        defaultMessage: 'Examination Date'
    },
    wcInjuryDoctorFirstName: {
        id: 'pe.fnol.wc.views.injury.Doctor\'s_First Name',
        defaultMessage: 'Doctor\'s First Name'
    },
    wcInjuryDoctorLastName: {
        id: 'pe.fnol.wc.views.injury.Doctor\'s_Last Name',
        defaultMessage: 'Doctor\'s Last Name'
    },
    wcInjuryDoctorFirstNameKanji: {
        id: 'pe.fnol.wc.views.injury.Doctor\'s_First Name Kanji',
        defaultMessage: 'Doctor\'s First Name Kanji'
    },
    wcInjuryDoctorLastNameKanji: {
        id: 'pe.fnol.wc.views.injury.Doctor\'s_Last Name Kanji',
        defaultMessage: 'Doctor\'s Last Name Kanji'
    },
    wcInjuryBodyInjuries: {
        id: 'pe.fnol.wc.views.injury.Body Injuries',
        defaultMessage: 'Body Injuries'
    },
    wcInjuryAdditionalBodyInjury: {
        id: 'pe.fnol.wc.views.injury.Additional body injury?',
        defaultMessage: 'Additional body injury?'
    },
    wcInjuryAddAnotherBodyInjury: {
        id: 'pe.fnol.wc.views.injury.Add Another Body Injury',
        defaultMessage: 'Add Another Body Injury'
    },
    wcInjuryBodyArea: {
        id: 'pe.fnol.wc.views.injury.Body Area',
        defaultMessage: 'Body Area #{bodyAreaCount}'
    },
    wcInjuryAreaOfBody: {
        id: 'pe.fnol.wc.views.injury.Area of Body',
        defaultMessage: 'Area of Body'
    },
    wcInjurySelectAreaOfBody: {
        id: 'pe.fnol.wc.views.injury.Select Area of Body',
        defaultMessage: '-- Select Area of Body --'
    },
    wcInjuryBodyPart: {
        id: 'pe.fnol.wc.views.injury.Body Part',
        defaultMessage: 'Body Part'
    },
    wcInjurySelectBodyPart: {
        id: 'pe.fnol.wc.views.injury.Select Body Part',
        defaultMessage: '-- Select Body Part --'
    },
    wcInjuryBodyPartDescription: {
        id: 'pe.fnol.wc.views.injury.Body Part Description',
        defaultMessage: 'Body Part Description'
    },
    wcInjurySelectBodyPartDescription: {
        id: 'pe.fnol.wc.views.injury.Select Body Part Description',
        defaultMessage: '-- Select Body Part Description --'
    },
    wcInjurySide: {
        id: 'pe.fnol.wc.views.injury.Side',
        defaultMessage: 'Side'
    },
    wcInjurySelectSide: {
        id: 'pe.fnol.wc.views.injury.Select Side',
        defaultMessage: '-- Select Side --'
    },
    wcInjuryRemoveBodyInjury: {
        id: 'pe.fnol.wc.views.injury.Remove Body Injury',
        defaultMessage: 'Remove Body Injury'
    },
    wcInjuryModalRemoveBodyInjury: {
        id: 'pe.fnol.wc.views.injury.Are you sure you want to remove Body Injury #: {bodyPartDetail}?',
        defaultMessage: 'Are you sure you want to remove Body Injury #: {bodyPartDetail}?'
    },
    wcInjuryRemoveButton: {
        id: 'pe.fnol.wc.views.injury.Remove',
        defaultMessage: 'Remove'
    },
    wcInjuryOccurredInCourseOfEmployment: {
        id: 'pe.fnol.wc.views.injury.Did the injury occur in course of Employment?',
        defaultMessage: 'Did the injury occur in course of Employment?'
    },
    wcCreateDraftClaim: {
        id: 'pe.fnol.wc.config.FlowConfig.Creating draft claim...',
        defaultMessage: 'Creating draft claim...'
    },
    wcCreateDraftErrorTitle: {
        id: 'pe.fnol.wc.config.FlowConfig.Error creating claim',
        defaultMessage: 'Error creating claim'
    },
    wcCreateDraftErrorMessage: {
        id: 'pe.fnol.wc.config.FlowConfig.An error occurred attempting to create a claim.',
        defaultMessage: 'An error occurred attempting to create a claim.'
    },
    describeWhatHappendTitle: {
        id: 'pe.fnol.wc.views.what.Describe what happened',
        defaultMessage: 'Describe what happened'
    },
    connectTeamNotice: {
        id: 'pe.fnol.wc.views.what.connectTeamNotice',
        defaultMessage: 'If you have a questons about your selection please reach out to our claims team at (855)921-3164. '
    },
    injuryCauseSourceLabel: {
        id: 'pe.fnol.wc.views.what.Injury Cause/Source',
        defaultMessage: 'Injury Cause/Source'
    },
    selectInjuryCauseSourceLabel: {
        id: 'pe.fnol.wc.views.what.Select Injury Cause/Source',
        defaultMessage: '-- Select Injury Cause/Source --'
    },
    detailCauseOfIncidentLabel: {
        id: 'pe.fnol.wc.views.what.Indicate who or what caused the incident, and provide other details that would be helpful.',
        defaultMessage: 'Indicate who or what caused the incident, and provide other details that would be helpful.'
    },
    dateEmployerNotifiedLabel: {
        id: 'pe.fnol.wc.views.what.Date Employer Notified',
        defaultMessage: 'Date Employer Notified'
    },
    submitAsReportLabel: {
        id: 'pe.fnol.wc.views.what.Submit as report only?',
        defaultMessage: 'Submit as report only?'
    },
    injuredEmployeeLabel: {
        id: 'pe.fnol.wc.views.what.Injured employee',
        defaultMessage: 'Injured employee'
    },
    firstNameLabel: {
        id: 'pe.fnol.wc.views.what.First Name',
        defaultMessage: 'First Name'
    },
    lastNameLabel: {
        id: 'pe.fnol.wc.views.what.Last Name',
        defaultMessage: 'Last Name'
    },
    phoneNumberLabel: {
        id: 'pe.fnol.wc.views.what.Phone Number',
        defaultMessage: 'Phone Number'
    },
    socialSecurityNumberLabel: {
        id: 'pe.fnol.wc.views.what.Social Security Number',
        defaultMessage: 'Social Security Number'
    },
    dateOfBirthLabel: {
        id: 'pe.fnol.wc.views.what.Date of Birth',
        defaultMessage: 'Date of Birth'
    },
    genderLabel: {
        id: 'pe.fnol.wc.views.what.Gender',
        defaultMessage: 'Gender'
    },
    selectGenderLabel: {
        id: 'pe.fnol.wc.views.what.Select Gender',
        defaultMessage: '-- Select Gender --'
    },
    policyLocation: {
        id: 'pe.fnol.wc.views.what.Policy Location',
        defaultMessage: 'Policy Location'
    },
    selectPolicyLocation: {
        id: 'pe.fnol.wc.views.what.Select Policy Location',
        defaultMessage: '-- Select Policy Location --'
    }
});
