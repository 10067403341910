import { setComponentMapOverrides } from '@jutro/legacy/uiconfig';

import FNOLWTWizard from './FNOLWTWizard.pe';
import FNOLWTVehiclesPage from './pages/Vehicles/FNOLWTVehiclesPage.pe';
// import FNOLPATypeOfIncidentPage from './pages/TypeOfIncident/TypeOfIncidentPage.pe';
// import FNOLPATypeOfRepairPage from './pages/TypeOfRepair/TypeOfRepairPage.pe';
// import FNOLPARepairFacilityPage from './pages/RepairFacility/RepairFacilityPage.pe';
// import FNOLPACollisionDetailsPage from './pages/Details/Collision/CollisionDetailsPage.pe';
// import FNOLPAGlassDetailsPage from './pages/Details/Glass/GlassDetailsPage.pe';
// import FNOLPATheftDetailsPage from './pages/Details/Theft/TheftDetailsPage.pe';
// import FNOLPARepairFacilityMapPage from './pages/RepairFacilityMap/RepairFacilityMapPage.pe';
// import FNOLPALossTypePage from './pages/LossType/FNOLPALossTypePage.pe';
// import FNOLPAContactAdminPage from './pages/ContactAdmin/FNOLPAContactAdminPage.pe';
// import FNOLPAIncidentsPage from './pages/Incidents/FNOLPAIncidentsPage.pe';

setComponentMapOverrides(
    {
        FNOLWTWizard: { component: 'FNOLWTWizard' },
        // FNOLPATypeOfIncidentPage: { component: 'FNOLPATypeOfIncidentPage' },
        FNOLWTVehiclesPage: { component: 'FNOLWTVehiclesPage' },
        // FNOLPATypeOfRepairPage: { component: 'FNOLPATypeOfRepairPage' },
        // FNOLPARepairFacilityPage: { component: 'FNOLPARepairFacilityPage' },
        // FNOLPACollisionDetailsPage: { component: 'FNOLPACollisionDetailsPage' },
        // FNOLPAGlassDetailsPage: { component: 'FNOLPAGlassDetailsPage' },
        // FNOLPATheftDetailsPage: { component: 'FNOLPATheftDetailsPage' },
        // FNOLPARepairFacilityMapPage: { component: 'FNOLPARepairFacilityMapPage' },
        // FNOLPALossTypePage: { component: 'FNOLPALossTypePage' },
        // FNOLPAContactAdminPage: { component: 'FNOLPAContactAdminPage' },
        // FNOLPAIncidentsPage: { component: 'FNOLPAIncidentsPage' }
    },
    {
        FNOLWTWizard,
        // FNOLPATypeOfIncidentPage,
        FNOLWTVehiclesPage: FNOLWTVehiclesPage,
        // FNOLPATypeOfRepairPage,
        // FNOLPARepairFacilityPage,
        // FNOLPACollisionDetailsPage,
        // FNOLPAGlassDetailsPage,
        // FNOLPATheftDetailsPage,
        // FNOLPARepairFacilityMapPage,
        // FNOLPALossTypePage,
        // FNOLPAContactAdminPage,
        // FNOLPAIncidentsPage
    }
);

export {
    FNOLWTWizard as default,
    // FNOLPAContactAdminPage
};
