import React, { useState } from 'react';
import _ from 'lodash'
import CoveragePartSchedule from './CommonCoveragePartSchedule/CommonCoveragePartSchedule';


const CommonCovPartSchedule = (props) => {

    const {
        id: scheduleId,
        scheduleContainer,
        setCoveragePartClauses,
        isEditing,
        setIsEditing,
        isEditable,
        wizardData: submissionVM,
        coveragePartSchedule,
        updateScheduleService,
        updateAdditionalIntestSchedule,
        schedulePath,
        scheduleLable,
        scheduleFormHeader,
        onValidate,
        visibleOfDivider,
        onChangeScheduleItem
    } = props

    const [stackOfOpenScheduleID, setStackOfOpenedScheduleID] = useState([])

    const {
        jobID,
        sessionUUID,
    } = submissionVM.value


    return (
            <div className="formCoveragesContainer">
                {coveragePartSchedule && <CoveragePartSchedule
                    jobID={jobID}
                    sessionUUID={sessionUUID}
                    schedule={coveragePartSchedule}
                    isEditing={isEditing}
                    setIsEditing={setIsEditing}
                    isEditable={isEditable}
                    scheduleContainer={scheduleContainer}
                    setCoveragePartClauses={setCoveragePartClauses}
                    updateScheduleService={updateScheduleService}
                    updateAdditionalIntestSchedule={updateAdditionalIntestSchedule}
                    schedulePath={schedulePath}
                    scheduleLable={scheduleLable}
                    scheduleFormHeader={scheduleFormHeader}
                    onValidate={onValidate}
                    scheduleId={scheduleId}
                    visibleOfDivider={visibleOfDivider}
                    onChangeScheduleItem={onChangeScheduleItem}
                    stackOfOpenScheduleID={stackOfOpenScheduleID}
                    setStackOfOpenedScheduleID={setStackOfOpenedScheduleID}
                />}
            </div>
    )
}

export default CommonCovPartSchedule