import React from 'react';
import _ from 'lodash';
import { ProductUtil } from 'wnice-portals-util-react';
import { TooltipIcon } from '@jutro/components';
import styles from './PolicyTypeWithPCData.module.scss';
import messages from './PolicyTypeWithPCData.messages'

function PolicyTypeWithPCData(props) {
  const {
    policySummariesFromApi,
    rowData,
    showIMTooltip = false
  } = props

  if(policySummariesFromApi.length === 0){
    return ProductUtil.getProductName(rowData.productCode)
  }
  const correspondPolicy = policySummariesFromApi.find((policy) => {
    const firstPeriod = _.first(policy.periods)
    return firstPeriod.policyId === rowData.policyNumber
  })

  if(!correspondPolicy){
    // eslint-disable-next-line no-console
    console.log("Not find corresponse policy:", rowData)
    return ProductUtil.getProductName(rowData.productCode)
  }
  const activePeriod = _.first(correspondPolicy.periods);
  const productCode = _.get(activePeriod, 'productCode_Ext.productCode');
  const policyType = _.get(activePeriod, 'policyType_Ext');
  const productName = ProductUtil.getProductName(productCode);

  if (policyType !== null && (productCode === ProductUtil.PA_PRODUCT_CODE || productCode === ProductUtil.HOP_PRODUCT_CODE)) {
    if (policyType === 'Named Non-Owner' && productCode === ProductUtil.PA_PRODUCT_CODE) {
        return (
            <div>
                <span>Select</span>&nbsp;
                <span>{productName}</span>
            </div>
        )
    };
    if (policyType.indexOf(" ") === -1) {
        return  (
            <div>
                <span>{policyType}</span>&nbsp;
                <span>{productName}</span>
            </div>
        )
    };            
    const displayPolicyType = policyType.substring(0, policyType.indexOf(" "));
    return  (
        <div>
            <span>{displayPolicyType}</span>&nbsp;
            <span>{productName}</span>
        </div>
    )
  }
    if(productCode === 'InlandMarine' && showIMTooltip){
      return (
        <div>
          <span className={styles.imType}>{productName}</span>
          <TooltipIcon
            trigger="mouseenter"
            className={styles.tooltip}
            id="tooltip-icon"
            text={messages.imTooltip}
          />
        </div>
      )
  }
  return productName;
}

export default PolicyTypeWithPCData;