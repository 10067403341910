import { withAuthenticationContext } from '@xengage/gw-digital-auth-react';
import { wizardProps } from '@xengage/gw-portals-wizard-react';

import { FNOLGLLossTypePage } from 'wni-capability-fnol-gl-react';
import { FNOLCPLossTypePage } from 'wni-capability-fnol-cp-react';

import { FNOLIMLossTypePage } from 'wni-capability-fnol-im-react';
import { FNOLCRLossTypePage } from 'wni-capability-fnol-cr-react';

import { ProductUtil } from 'wnipe-portals-util-react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { withRouter } from 'react-router-dom';

function FNOLCPPLossTypePage(props) {
    const {
        wizardData: claimVM
    } = props;

    const lineSelected = _.get(claimVM.value, 'policy.lobCode_Ext');

    switch(lineSelected) {
        case ProductUtil.FNOL_LOB_GL:
            return <FNOLGLLossTypePage {...props} />;
        case ProductUtil.FNOL_LOB_CP:
            return <FNOLCPLossTypePage {...props} />;
        case ProductUtil.FNOL_LOB_IM:
            return <FNOLIMLossTypePage {...props} />;
        case ProductUtil.FNOL_LOB_CR:
            return <FNOLCRLossTypePage {...props} />;
        default:
            return null; 
    }
}

FNOLCPPLossTypePage.propTypes = wizardProps;
FNOLCPPLossTypePage.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func,
    }).isRequired,
};
export default withRouter(withAuthenticationContext(FNOLCPPLossTypePage));
