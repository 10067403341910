import { Button } from '@jutro/components';
import { Flex } from '@jutro/layout';
import _ from 'lodash';
import React, {
    useState,
    useEffect,
    useCallback
} from 'react';
import messages from './CommonScheduleItemForm.messages';
import IMCoverageUtil from '../../../util/IMCoverageUtil';
import IMScheduleDetailsPropertyField from "../../IMScheduleTable/IMScheduleDetailsComponents/IMScheduleDetailsPropertyField";
import IMScheduleDetailsCovTermField from "../../IMScheduleTable/IMScheduleDetailsComponents/IMScheduleDetailsCovTermField";
// eslint-disable-next-line import/no-cycle
import CommonCoveragePartSchedule from '../CommonCoveragePartSchedule/CommonCoveragePartSchedule';


const CommonScheduleItemForm = (props) => {
    const [showErrors, setShowErrors] = useState(false)
    const {
        jobID,
        sessionUUID,
        propertyInfos,
        coveragePropertyInfos,
        scheduleItem: originScheduleItem = {},
        handleScheduleItemCancel,
        handleSaveScheduleItem,
        handleSaveAdditionalInterest,
        isEditable,
        onValidate,
        scheduleId,
        setIsEditing,
        scheduleFormHeader,
        onChangeScheduleItem,
        stackOfOpenScheduleID,
        setStackOfOpenedScheduleID,
        updateAdditionalIntestSchedule,
    } = props;

    const {
        '@deserialization-class': deserializationClass,
        publicId_Ext: scheduleItemPublicID
    } = originScheduleItem

    const isAdditionalInterestScheduledItem = deserializationClass === 'wni.edge.capabilities.policyjob.lob.inlandmarine.coverages.dto.IMAdditionalInterestScheduledItemDTO'
    
    const [scheduleItem, setScheduleItem] = useState(originScheduleItem);

    // Change schedule Item when switch scheduleItem
    useEffect(() => {
        setScheduleItem(originScheduleItem)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [scheduleItemPublicID])
    
    useEffect(() => {
        if (onValidate) {
            onValidate(!IMCoverageUtil.isScheduleItemFormInvalid(scheduleItem, propertyInfos), scheduleId);
        }
        return () => {
            if (onValidate) {
                onValidate(true, scheduleId);
            }
        }
    }, [onValidate, scheduleItem, propertyInfos, scheduleId])

    const setScheduleItemFunc = (setPropertyFieldFunc) => {
        const newScheduleItem = setPropertyFieldFunc(scheduleItem)
        setScheduleItem(newScheduleItem)
    }

    const propertyFieldComponents = propertyInfos
        .sort((a, b) => a.order - b.order)
        .map(propertyInfo => {
            const propertyInfoID = propertyInfo.id
            const propertyInfoItemData = scheduleItem.itemData[propertyInfoID]
            return <IMScheduleDetailsPropertyField
                id={propertyInfoID}
                key={propertyInfoID}
                setScheduleItemFunc={setScheduleItemFunc}
                propertyInfo={propertyInfo}
                onValidate={onValidate}
                propertyInfoItemData={propertyInfoItemData}
                showErrors={showErrors}
                isEditable={isEditable}
                setIsEditing={setIsEditing}
            />
        })

    const termFieldComponents = coveragePropertyInfos
        .sort((a, b) => a.order - b.order)
        .map(termPropertyInfo => {
            const termCode = termPropertyInfo.id
            const term = scheduleItem.scheduleItemTerms
                .find(t => t.code_Ext === termCode)
            if (_.isNil(term)) {
                return null;
            }
            return <IMScheduleDetailsCovTermField
                key={termCode}
                term={term}
                setScheduleItemFunc={setScheduleItemFunc}
                onValidate={onValidate}
                showErrors={showErrors}
                isEditable={isEditable}
                setIsEditing={setIsEditing}
                onChangeScheduleItem={onChangeScheduleItem}
            />
        })

    const handleSave = useCallback((currentScheduleItem) => {
        const isScheduleItemInvalid = IMCoverageUtil.isScheduleItemFormInvalid(currentScheduleItem, propertyInfos)
        if (isScheduleItemInvalid) {
            setShowErrors(true)
            return false
        }
        handleSaveScheduleItem(currentScheduleItem)
    }, [handleSaveScheduleItem, propertyInfos])

    const openedScheduleItemID = stackOfOpenScheduleID.at(-1)

    return (
        <>
            <h4>{scheduleFormHeader}</h4>
            {propertyFieldComponents}
            {termFieldComponents}
            {isAdditionalInterestScheduledItem && <CommonCoveragePartSchedule
                scheduleId={_.get(scheduleItem, 'scheduledAdditionalInterest.schedulePatternCode_Ext')}
                jobID={jobID}
                sessionUUID={sessionUUID}
                setCoveragePartClauses={(newScheduleItem) => {
                    // Only update additional interest part
                    
                    setScheduleItem({
                        ...scheduleItem,
                        scheduledAdditionalInterest: newScheduleItem.scheduledAdditionalInterest
                    })
                    handleSaveAdditionalInterest(newScheduleItem)
                }}
                setIsEditing={setIsEditing}
                isEditable={isEditable}
                schedule={_.get(scheduleItem, 'scheduledAdditionalInterest')}
                updateScheduleService={(
                    _jobID,
                    _sessionUUID,
                    scheduleRequest,
                    authHeader
                ) => updateAdditionalIntestSchedule(
                    _jobID,
                    _sessionUUID,
                    scheduleItemPublicID,
                    scheduleRequest,
                    authHeader
                )}
                schedulePath='scheduledAdditionalInterest'
                onValidate={onValidate}
                stackOfOpenScheduleID={stackOfOpenScheduleID}
                setStackOfOpenedScheduleID={setStackOfOpenedScheduleID}
            />}
            <Flex gap="small" justifyContent="right" className="mb-10">
                <Button
                    variant="secondary"
                    onClick={handleScheduleItemCancel}
                    label={messages.Cancel}
                    disabled={openedScheduleItemID !== scheduleId}
                />
                <Button
                    onClick = {()=> handleSave(scheduleItem)}
                    label={messages.SaveAndContinue}
                    disabled={openedScheduleItemID !== scheduleId}
                />
            </Flex>
        </>
        
    );
};

export default CommonScheduleItemForm;
