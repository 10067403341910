import React, {
    useContext, useEffect, useState, useCallback, useMemo
} from 'react';
import _ from 'lodash';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { WizardPage, wizardProps } from '@xengage/gw-portals-wizard-react';
import { WizardPageTemplate } from 'gw-portals-wizard-components-ui';
import {
    ViewModelServiceContext,
    withViewModelService,
    ViewModelForm
} from '@xengage/gw-portals-viewmodel-react';
import { useTranslator } from '@jutro/locale';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { saveFNOLDetails } from 'gw-capability-fnol-common-react';
import Claim from '../../models/Claim';
import Address from '../../models/Address';
import metadata from './ContactDetailsPage.metadata.json5';
import messages from '../../FNOL.messages';

function FNOLContactDetailsPage(props) {
    const translator = useTranslator();
    const viewModelService = useContext(ViewModelServiceContext);
    const { wizardData: claimVM, updateWizardData } = props;
    const [availableContacts, setAvailableContacts] = useState([]);
    const newContact = {
        placeholder: true
    };
    const {
        onValidate,
        isComponentValid,
        initialValidation,
        registerComponentValidation
    } = useValidation('FNOLContactDetailsPage');
    const { authHeader } = useAuthentication();

    const policyType = _.get(claimVM, 'value.policy.policyType');

    const isCLLine = useMemo(
        () => {
            return (policyType === 'WorkersComp' || policyType === 'BusinessAuto')
        },
        [policyType]
    );

    const isGLOrCPLine = useMemo(
        () => {
            return (policyType === 'GeneralLiability' || policyType === 'CommercialProperty')
        },
        [policyType]
    );

    const getAvailableContacts = useCallback(
        (newClaimVM) => {
            const contactsData = newClaimVM.value
                .availableContacts()
                .filter((contact) => contact.subtype === 'Person');
            contactsData.push(newContact);
            // init index for New Contact
            let newContactIndex = 1;
            const dropDownContacts = contactsData.map((contact) => {
                const val = contact.placeholder
                    ? translator(messages.otherPerson)
                    : contact.getDisplayName(translator(messages.newContact));
                // handle the display name of multiple New Contact
                if (val === translator(messages.newContact)) {
                    // skip the rename of first New Contact
                    if (newContactIndex === 1) {
                        newContactIndex += 1;
                        // reset displayName to avoid duplicate of index
                        _.set(contact, 'displayName', val);
                    } else {
                        const newContactVal = `${val} #${newContactIndex}`;
                        _.set(contact, 'displayName', newContactVal);
                        newContactIndex += 1;
                        return {
                            code: newContactVal,
                            name: newContactVal
                        }
                    }
                }
                return {
                    code: val,
                    name: val
                };
            });
            return dropDownContacts;
        },
        [newContact, translator]
    );

    const initializeVM = useCallback(() => {
        const submittingClaimVM = viewModelService.create(
            new Claim(claimVM.value),
            'cc',
            'wni.edge.capabilities.claim.fnol.dto.WniFnolDTO',
            {
                SubmittingClaim: true
            }
        );
        const newClaimVM = viewModelService.clone(submittingClaimVM);
        updateWizardData(newClaimVM);
        return newClaimVM;
    }, [claimVM, updateWizardData, viewModelService]);

    const isContactNumberCheck = useCallback(() => {
        if (claimVM.mainContact.aspects.valid && claimVM.mainContact.aspects.subtreeValid) {
            return true;
        }
        return false;
    }, [claimVM]);

    useEffect(
        () => {
            const newClaimVM = initializeVM();
            if (_.isEmpty(newClaimVM.value.mainContact)) {
                newClaimVM.value.mainContact = newClaimVM.value.createContact();
                newClaimVM.value.mainContact.primaryPhoneType = 'work';
                newClaimVM.value.mainContact.displayName = translator(messages.newContact);
                newClaimVM.value.mainContact.primaryAddress = new Address();
            }
            const getContactList = getAvailableContacts(newClaimVM);
            setAvailableContacts(getContactList);
        },
        // execute once
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    useEffect(() => {
        registerComponentValidation(isContactNumberCheck);
    }, [isContactNumberCheck, registerComponentValidation]);

    const handleContactChange = useCallback(
        (value) => {
            if (value === 'Other Person') {
                claimVM.mainContact.value = claimVM.value.createContact();
                claimVM.mainContact.primaryPhoneType.value = 'work';
                claimVM.mainContact.displayName.value = translator(messages.newContact);
                claimVM.mainContact.primaryAddress.value = new Address();
                const getContactList = getAvailableContacts(claimVM);
                setAvailableContacts(getContactList);
            }
            if (value !== 'Other Person') {
                const selectedMainContact = _.filter(claimVM.value.contacts, (contact) => {
                    const contactName = contact.displayName
                        ? contact.displayName
                        : contact.getDisplayName();
                    return contactName === value;
                });
                selectedMainContact[0].primaryAddress = !_.isEmpty(
                    selectedMainContact[0].primaryAddress
                )
                    ? selectedMainContact[0].primaryAddress
                    : new Address();
                selectedMainContact[0].displayName = !_.isEmpty(selectedMainContact[0].displayName)
                    ? selectedMainContact[0].displayName
                    : selectedMainContact[0].getDisplayName();
                _.set(claimVM, 'mainContact.value', selectedMainContact[0]);
                updateWizardData(claimVM);
            }
            _.set(claimVM, 'mainContact.isReportedByTypeOptional_Ext', false)
        },
        [claimVM, getAvailableContacts, translator, updateWizardData]
    );

    const handleNameChange = useCallback(
        (value, path) => {
            _.set(claimVM, path, value);
            const selectedContactArray = _.filter(claimVM.contacts.value, (contact) => {
                if ('tempID' in contact) {
                    if (contact.tempID === claimVM.mainContact.tempID.value) {
                        return contact;
                    }
                } else if(contact.publicID){
                    if(contact.publicID === claimVM.mainContact.value.publicID){
                        return contact;
                    }
                }
                return null;
            });
            const newDisplayName = selectedContactArray[0].getDisplayName(
                translator(messages.newContact)
            );
            _.set(claimVM, 'mainContact.displayName.value', newDisplayName);
            updateWizardData(claimVM);
            const addNew = getAvailableContacts(claimVM);
            setAvailableContacts(addNew);
        },
        [claimVM, getAvailableContacts, translator, updateWizardData]
    );

    const getAddressLineFields = useCallback(() => {
        if (claimVM.value.mainContact && claimVM.value.mainContact.primaryAddress) {
            return (
                <div>
                    <p>{claimVM.value.mainContact.primaryAddress.addressLine1}</p>
                    <p>{claimVM.value.mainContact.primaryAddress.addressLine2}</p>
                    <p>{claimVM.value.mainContact.primaryAddress.addressLine3}</p>
                </div>
            );
        }
        return '';
    }, [claimVM]);

    const getPrimaryContactDetailsHeader = () => {
        if(isCLLine) {
            return messages.CLPrimaryContactHeader;
        }
        if(isGLOrCPLine) {
            return messages.GLCPPrimaryContactHeader
        }
        return messages.PLPrimaryContactHeader
    }

    const overrides = {
        '@field': {
            showOptional: true,
            phoneWide: {
                labelPosition: 'top'
            }
        },
        workNumberField: {
            required: _.get(claimVM, 'mainContact.primaryPhoneType.value.code') === 'work'
        },
        homeNumberField: {
            required: _.get(claimVM, 'mainContact.primaryPhoneType.value.code') === 'home'
        },
        mobileNumberField: {
            required: _.get(claimVM, 'mainContact.primaryPhoneType.value.code') === 'mobile'
        },
        fnolContactField: {
            availableValues: availableContacts,
            onValueChange: handleContactChange,
            showOptional: false
        },
        firstNameField: {
            visible: !(_.get(claimVM, 'mainContact.policyRole.value.code') === 'insured'),
            onValueChange: handleNameChange
        },
        lastNameField: {
            visible: !(_.get(claimVM, 'mainContact.policyRole.value.code') === 'insured'),
            onValueChange: handleNameChange
        },
        addressField: {
            visible: false,
            value: getAddressLineFields()
        },
        addressLine1Field: {
            readOnly: _.get(claimVM, 'mainContact.policyRole.value.code') === 'insured'
        },
        addressLine2Field: {
            readOnly: _.get(claimVM, 'mainContact.policyRole.value.code') === 'insured'
        },
        addressLine3Field: {
            readOnly: _.get(claimVM, 'mainContact.policyRole.value.code') === 'insured'
        },
        newContactCityField: {
            readOnly: _.get(claimVM, 'mainContact.policyRole.value.code') === 'insured'
        },
        newContactZipCodeField: {
            readOnly: _.get(claimVM, 'mainContact.policyRole.value.code') === 'insured'
        },
        newContactStateField: {
            readOnly: _.get(claimVM, 'mainContact.policyRole.value.code') === 'insured'
        },
        primaryContactDetailsHeader: {
            content: getPrimaryContactDetailsHeader()
        }
    };

    const readValue = useCallback(
        (id, path) => {
            return readViewModelValue(metadata.pageContent, claimVM, id, path, overrides);
        },
        [claimVM]
    );

    const onNext = useCallback(async () => {
        claimVM.value = await saveFNOLDetails(claimVM.value, authHeader)
        return claimVM
    }, [authHeader, claimVM]);

    if (_.isEmpty(claimVM.value.mainContact)) {
        return null;
    }

    return (
        <WizardPage
            cancelLabel={translator(messages.fnolSaveandExit)}
            disableNext={!isComponentValid}
            skipWhen={initialValidation}
            template={WizardPageTemplate}
            onNext={onNext}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={claimVM}
                resolveValue={readValue}
                overrideProps={overrides}
                onModelChange={updateWizardData}
                onValidationChange={onValidate}
            />
        </WizardPage>
    );
}

FNOLContactDetailsPage.propTypes = wizardProps;
export default withViewModelService(FNOLContactDetailsPage);
