import React, { useEffect, useState, useContext, useMemo } from 'react';
import _ from 'lodash';
import { useTranslator } from '@jutro/locale';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import metadata from './GLVehicleDetail.metadata.json5';
import messages from '../../FNOLGLIncidentDetailsPage.messages';
import { createNewCompanyWithoutPublicID, createNewPersonWithoutPublicID } from '../../utils/NewContactUtil';
import { newLocation, getFullVehicleDisplayName } from '../../utils/VehicleUtil';
import { Button } from '@jutro/legacy/components';

const AddNewVehicle = 'AddNewVehicle';
const AddNewPerson = 'AddNewPerson';
const AddNewCompany = 'AddNewCompany';
function GLVehicleDetail(props) {
    const {
        vehicleVM,
        relatedContacts,
        onValueChange,
        syncWizardData,
        onDetailCancel,
        onDetailSave,
    } = props

    const {
        vehicleOwnerContactPublicID,
        vehicleOwner,
        availableVehicles,
        availableLossOccureds,
        locationMap,
        driverMap,
        driver
    } = vehicleVM.value;

    const translator = useTranslator();
    const viewModelService = useContext(ViewModelServiceContext);
    const { onValidate } = useValidation('GLVehicleDetail');
    const [showErrors, setShowErrors] = useState(false);

    const [vehicleDamage, updataVehicleDamage] = useState();
    const [vehicleOwnerPublicID, updateVehicleOwnerPublicID] = useState(vehicleOwnerContactPublicID);
    const [vehicleLocationPublicID, updateVehicleLocationPublicID] = useState();
    const [vehicleOwnerContact, updateVehicleOwnerContact] = useState(vehicleOwner);
    const [vehicleDriver, updateVehicleDriver] = useState(driver);
    const [selectVehicleValue, updateSelectVehicleValue] = useState();
    const relatedPersonContacts = relatedContacts?.filter((contact) => ['UserContact', 'Person'].includes(contact.subtype));
    
    useEffect(() => {
        const lossPartyType = _.get(vehicleVM.value, 'lossPartyType');
        let vehicleDamaged;
        if(lossPartyType === 'third_party') {
            vehicleDamaged = true;
        }
        if(lossPartyType === 'insured') {
            vehicleDamaged = false;
        }
        const locationID = _.get(vehicleVM.value, 'locationID');
        const vehicleID = _.get(vehicleVM.value, 'vehicle.publicID');
        updataVehicleDamage(vehicleDamaged);
        updateVehicleLocationPublicID(locationID);
        updateSelectVehicleValue(vehicleID);
    }, [vehicleVM]);

    const getAvailableVehicleOptions = () => {
        const newVehicle = [
            {
                code: AddNewVehicle,
                name: translator(messages.AddNewVehicle)
            }
        ];
        if (!_.isEmpty(availableVehicles)) {
            const existingVehicles = availableVehicles.map((vehicle) => {
                return {
                    code: _.get(vehicle, 'publicID'),
                    name: getFullVehicleDisplayName(vehicle)
                }
            });
            return existingVehicles.concat(newVehicle);
        }
        return newVehicle;
    };

    const onChangeVehicleLossPartyType = (val) => {
        const lossType = val ? 'third_party': 'insured';
        updataVehicleDamage(val);
        _.set(vehicleVM.value, 'lossPartyType', lossType);
        syncWizardData(vehicleVM);
    };

    const getLocationOptions = () => {
        const generateOptions = locationMap.map((location) => {
            return {
                ...location,
                code: location.publicID,
                name: location.displayName,
            };
        });
        generateOptions.push(newLocation);
        return generateOptions;
    };

    const getAvailableDriverOptions = () => {
        const newDriver = [
            {
            code: AddNewPerson,
            name: translator(messages.addNewPerson)
            }
        ];
        const existingDrivers = relatedPersonContacts?.map((contact) => {
            return {
                code: _.get(contact, 'publicID'),
                name: contact.displayName
            }
        })
        return existingDrivers.concat(newDriver);
    };

    const getOwnerNameOptions = () => {
        const generateOwnerOptions = relatedContacts.map((contact) => {
            return {
                ...contact,
                code: contact.publicID,
                name: contact.displayName,
            };
        });
        generateOwnerOptions.push(...[
            {
                code: AddNewPerson, 
                name: translator(messages.addNewPerson)
            },
            {
                code: AddNewCompany, 
                name: translator(messages.addNewCompany)
            }
        ]);
        return generateOwnerOptions;
    };

    const onOwnerSelectorChange = (value) => {
        let currentOwner = null;
        if (value === AddNewPerson) {
            currentOwner = {
                publicID: AddNewPerson,
                ...createNewPersonWithoutPublicID()
            };
        } else if (value === AddNewCompany) {
            currentOwner = {
                publicID: AddNewCompany,
                ...createNewCompanyWithoutPublicID()
            };
        } else {
            currentOwner = relatedContacts.find(item => item.publicID === value);
        }
        const newVehicleVm = viewModelService.clone(vehicleVM);
        _.set(newVehicleVm.value, 'vehicleOwner', currentOwner);
        _.set(newVehicleVm.value, 'vehicleOwnerContactPublicID', currentOwner.publicID);
        updateVehicleOwnerPublicID(currentOwner.publicID);
        updateVehicleOwnerContact(currentOwner);
        syncWizardData(newVehicleVm);
    };

    const onDriverSelectorChange = (value) => {
        let currentDriver = null;
        if (value === AddNewPerson) {
            currentDriver = {
                publicID: AddNewPerson,
                ...createNewPersonWithoutPublicID()
            };
        } else {
            currentDriver = driverMap.find(item => item.publicID === value);
        }
        const newVehicleVM = viewModelService.clone(vehicleVM);
        _.set(newVehicleVM.value, 'driver', currentDriver);
        updateVehicleDriver(currentDriver);
        syncWizardData(newVehicleVM);
    };

    const updateOwnerField = (value, path) => {
        const updateOwner = {
            ...vehicleOwner,
            [path]: value
        };
        updateVehicleOwnerContact(updateOwner);
        _.set(vehicleVM.value, 'vehicleOwner', updateOwner);
    };

    const updateDriverField = (value, path) => {
        const updateDriver = {
            ...driver,
            [path]: value
        };
        updateVehicleDriver(updateDriver);
        _.set(vehicleVM.value, 'driver', updateDriver);
    };

    const onOwnerDetailChange = (value, path) => {
        updateOwnerField(value, path);
        if (vehicleDriver?.publicID === vehicleOwnerContact?.publicID) {
            updateDriverField(value, path);
        }
    };

    const onDriverDetailChange = (value, path) => {
        updateDriverField(value, path);
        if (vehicleDriver?.publicID === vehicleOwnerContact?.publicID) {
            updateOwnerField(value, path);
        }
    };

    const writeValue = async (value, path) => {
        await onValueChange(value, path);
        if (!value) {
            return false;
        }
        switch (path) {
            case 'locationID':
                let addressValue = {};
                if (value === 'new') {
                    addressValue = {
                        displayName: newLocation.name,
                        country: newLocation.country,
                    };
                } else {
                    addressValue = locationMap.find(
                        (option) => option.publicID === value
                    );
                }
                const newVehicleVM = _.clone(vehicleVM);
                updateVehicleLocationPublicID(value);
                _.set(newVehicleVM.value, 'locationID', value);
                _.set(newVehicleVM.value, 'location', addressValue);
                syncWizardData(newVehicleVM);
                break;
            default:
                break;
        }
    };

    const onSaveClicked = () => {
        setShowErrors(false);
        const vehicleIncidentValid = vehicleVM.aspects.valid && vehicleVM.aspects.subtreeValid;
        if (!vehicleIncidentValid) {
            setShowErrors(true);
            return;
        }
        syncWizardData(vehicleVM);
        onDetailSave();
    };

    const onSelectVehicleChangeValue = (val) => {
        let vehicleObj = {};
        if (val === AddNewVehicle) {
            vehicleObj = {
                publicID: val
            }
        } else {
            vehicleObj = availableVehicles.find((v) => v.publicID === val);
        }
        const newVehicleIncidentVM = viewModelService.clone(vehicleVM);
        _.set(newVehicleIncidentVM.value, 'vehicle', vehicleObj);
        syncWizardData(newVehicleIncidentVM);
        updateSelectVehicleValue(val);
    };

    const getAvailableLossOccuredOptions = useMemo(() => {
        const lossDataArray = availableLossOccureds.map((code) => {
            return {
                code: code,
                name: translator({
                    id: `typekey.LossOccured.${code}`,
                    defaultMessage: `typekey.LossOccured.${code}`
                })
            };
        });
        return lossDataArray;
    }, [availableLossOccureds, translator]);
    
    const overrideProps = {
        '@field': {
            labelPosition: 'left',
            showOptional: true,
        },
        glVehicleDamageQuestion: {
            value: vehicleDamage,
            onValueChange: (val) => onChangeVehicleLossPartyType(val)
        },
        selectVehicle: {
            value: selectVehicleValue,
            availableValues: getAvailableVehicleOptions(),
            onValueChange: onSelectVehicleChangeValue
        },
        yearInput: {
            visible: !!selectVehicleValue
        },
        makeInput: {
            visible: !!selectVehicleValue
        },
        modelInput: {
            visible: !!selectVehicleValue
        },
        vehicleLocation: {
            availableValues: getLocationOptions(),
            value: vehicleLocationPublicID,
        },
        addressFields: {
            visible: !!vehicleLocationPublicID,
            model: vehicleVM,
            basePath: 'location',
            onValueChange: writeValue,
            onValidate,
            disabled: vehicleLocationPublicID && vehicleLocationPublicID !== 'new',
            showErrors
        },
        lossOccurred: {
           availableValues: getAvailableLossOccuredOptions
        },
        selectDriver: {
            availableValues: getAvailableDriverOptions(),
            value: _.get(vehicleDriver, 'publicID'),
            onValueChange: onDriverSelectorChange
        },
        vehicleDriverContainer: {
            visible: !_.isEmpty(vehicleDriver)
        },
        driverFirstName: {
            onValueChange: (value) => onDriverDetailChange(value, 'firstName')
        },
        driverLastName: {
            onValueChange: (value) => onDriverDetailChange(value, 'lastName')
        },
        vehicleOwnerName: {
            availableValues: getOwnerNameOptions(),
            value: vehicleOwnerPublicID,
            onValueChange: onOwnerSelectorChange,
            visible: vehicleDamage
        },
        vehicleOwnerPersonContainer: {
            visible: vehicleDamage
        },
        firstName: {
            visible: _.get(vehicleOwnerContact, 'subtype') === 'Person' || _.get(vehicleOwnerContact, 'subtype') === 'UserContact',
            onValueChange: (value) => onOwnerDetailChange(value, 'firstName'),
        },
        lastName: {
            visible: _.get(vehicleOwnerContact, 'subtype') === 'Person' || _.get(vehicleOwnerContact, 'subtype') === 'UserContact',
            onValueChange: (value) => onOwnerDetailChange(value, 'lastName'),
        },
        companyName: {
            visible: _.get(vehicleOwnerContact, 'subtype') === 'Company',
            onValueChange: (value) => onOwnerDetailChange(value, 'contactName'),
        }
    };

    const readValue = (fieldId, fieldPath) => {
        return readViewModelValue(
            metadata.componentContent, vehicleVM, fieldId, fieldPath, overrideProps
        );
    };

    const resolvers = {
        resolveCallbackMap: {
        },
        resolveComponentMap: {
        }
    };

    return (
        <>
            <ViewModelForm
                uiProps={metadata.componentContent}
                model={vehicleVM}
                overrideProps={overrideProps}
                onValidationChange={onValidate}
                onValueChange={writeValue}
                resolveValue={readValue}
                callbackMap={resolvers.resolveCallbackMap}
                componentMap={resolvers.resolveComponentMap}
                showErrors={showErrors}
            />
             <div className='d-flex flex-direction-row-reverse'>
                <Button
                    type="filled"
                    onClick={onSaveClicked}
                >{translator(messages.saveAndContinue)}</Button>
                <Button
                    className="mr-15 wni-button-link"
                    type="outlined"
                    onClick={() => {onDetailCancel()}}
                >{translator(messages.cancel)}</Button>
            </div>
        </>
    );
}

GLVehicleDetail.propTypes = {
};
GLVehicleDetail.defaultProps = {
};
export default GLVehicleDetail;
