import { setComponentMapOverrides } from '@jutro/legacy/uiconfig';
import FNOLHOWizard from './FNOLHOWizard.pe';
import FNOLHOTypeOfIncidentPage from './pages/TypeOfIncident/TypeOfIncidentPage.pe';
import FNOLHOIncidentDetailsPage from './pages/FNOLHOIncidentDetails/FNOLHOIncidentDetails.pe';

import FNOLFireDamagePage from './pages/FireDamage/FireDamagePage.pe';
import FNOLWaterDamagePage from './pages/WaterDamage/WaterDamagePage.pe';
import FNOLCriminalDamagePage from './pages/CriminalDamage/CriminalDamagePage.pe';

import LossLocationComponent from './pages/FNOLHOIncidentDetails/LossLocation/LossLocationComponent';
import SelectAddressComponent from './pages/FNOLHOIncidentDetails/LossLocation/SelectAddressComponent';

setComponentMapOverrides(
    {
        FNOLHOWizard: { component: 'FNOLHOWizard' },
        FNOLHOTypeOfIncidentPage: { component: 'FNOLHOTypeOfIncidentPage' },
        FNOLHOIncidentDetailsPage: { component: 'FNOLHOIncidentDetailsPage'},
        FNOLFireDamagePage: { component: 'FNOLFireDamagePage' },
        FNOLWaterDamagePage: { component: 'FNOLWaterDamagePage' },
        FNOLCriminalDamagePage: { component: 'FNOLCriminalDamagePage' },
        LossLocationComponent: { component: 'LossLocationComponent' },
        SelectAddressComponent: { component: 'SelectAddressComponent' }
    },
    {
        FNOLHOWizard,
        FNOLHOTypeOfIncidentPage,
        FNOLHOIncidentDetailsPage,
        FNOLFireDamagePage,
        FNOLWaterDamagePage,
        FNOLCriminalDamagePage,
        LossLocationComponent,
        SelectAddressComponent
    }
);

export {
    FNOLHOWizard as default,
    FNOLHOTypeOfIncidentPage,
    FNOLHOIncidentDetailsPage,
    FNOLFireDamagePage,
    FNOLWaterDamagePage,
    FNOLCriminalDamagePage,
    LossLocationComponent,
    SelectAddressComponent
};
