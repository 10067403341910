import { defineMessages } from 'react-intl';

export default defineMessages({
    addPersonButtonDefaultText: {
        id: 'fnol.common.views.fnol.additional-information.+ Add Reporter',
        defaultMessage: 'Add Officials or Responding Agency'
    },
    addPersonButtonPAText: {
        id: 'fnol.common.views.fnol.additional-information.Add Witnesses',
        defaultMessage: 'Add Witnesses or First Responders'
    },
    anotherPersonLinkDefaultText: {
        id: 'fnol.common.views.fnol.additional-information.Another person',
        defaultMessage: 'Another person'
    },
    anotherPersonLinkPAText: {
        id: 'fnol.common.views.fnol.additional-information.Add Person or witness',
        defaultMessage: 'Add Witness'
    },
    witnessesOrOtherPartiesText: {
        id: 'fnol.common.views.fnol.additional-information.Witnesses or Other Parties',
        defaultMessage: 'Witnesses or Other Parties'
    },
    respondingOfficialWCTitle: {
        id: 'fnol.common.views.fnol.additional-information.Responding Official',
        defaultMessage: 'Responding Official'
    },
    tipsForUploadFile: {
        id: 'fnol.common.views.fnol.additional-information.tipsForUploadFile',
        defaultMessage: 'You can upload any photos, paperwork, police report into Support Information Section below.'
    },
    dragAndDropAFileHere: {
        id: 'fnol.common.views.fnol.additional-information.dragAndDropAFileHere',
        defaultMessage: 'Drag & Drop a file here (only support .pdf, .png, .doc/docx)'
    },
    adFileTips: {
        id: 'fnol.common.views.fnol.additional-information.adFileTips',
        defaultMessage: 'Add photos, paperwork, policy report or documents that are relevant to the incident and may help with the claim.'
    },
})