import React, { useCallback, useState, useEffect, useMemo, useContext } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useModal } from '@jutro/components';
import {
    WizardPage,
    wizardProps
} from '@xengage/gw-portals-wizard-react';
import { WizardPageTemplate, WizardPageTemplateWithTitle } from 'gw-portals-wizard-components-ui';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { useTranslator } from '@jutro/locale';
import { Claim, fnolCommonMessages, ClaimUtil, FNOLLossLocation } from 'gw-capability-fnol-common-react';

import { FNOLService } from 'wni-capability-fnol';

import { withAuthenticationContext } from '@xengage/gw-digital-auth-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { withRouter } from 'react-router-dom';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { messages as commonMessages } from '@xengage/gw-platform-translations';
import PropertyIncidents from './PropertyIncidents/PropertyIncidents';
import metadata from './FNOLPAIncidentsPage.metadata.json5';
import messages from '../../FNOLPA.messages';
import Pedestrians from './Pedestrians/Pedestrians';

function FNOLPAIncidentsPage(props) {
    const modalApi = useModal();
    const viewModelService = useContext(ViewModelServiceContext);
    const {
        wizardData: claimVM,
        updateWizardData,
        history,
        history: {
            location: { state = {} }
        },
        authHeader
    } = props;
    const [isLoading, setIsLoading] = useState(false);
    const {
        loadingMask: { setLoadingMask },
    } = useDependencies('loadingMask');
    // const { FNOLService } = useDependencies('FNOLService');
    const [pageData] = useState(state);
    const translator = useTranslator();
    const [showErrors, setShowErrors] = useState(false);
    const {
        onValidate,
        isComponentValid,
        initialValidation,
        registerInitialComponentValidation
    } = useValidation('FNOLPAIncidentsPage');

    const LOB_NAME = ClaimUtil.getLobByPolicyType(_.get(claimVM, 'value.policy.policyType', 'personalAuto'));
    const displayPolicyType = ClaimUtil.getDisplayNameByPolicyType(_.get(claimVM, 'value.policy.policyType', 'personalAuto'));

    const setValidation = () => {
        setShowErrors(true)
        return false
    }

    const {
        lobs: {
            [LOB_NAME]: {
                // fixedPropertyIncidents_Ext: propertyIncidents = [],
                pedestrians_Ext: pedestrians = [],
                relatedContacts_Ext: relatedContacts = [],
                filteredRelatedContacts_Ext: filteredRelatedContacts = [],
                vehicleIncidents_Ext: vehicleIncidents = []
            }
        },
        lossCause
    } = claimVM.value

    const isClaimStatus = useCallback(() => {
        const { claimStatus } = pageData;
        return !_.isEmpty(claimStatus);
    }, [pageData]);


    const propertyIncidentsPath = `lobs.${LOB_NAME}.fixedPropertyIncidents_Ext`
    const propertyIncidents = _.get(claimVM.value, propertyIncidentsPath, [])

    const lossSubCauseAvailableValues = _.get(claimVM, 'lossSubCause_Ext.aspects.availableValues', [])

    const incidentsAddresses = _.get(claimVM.value, 'incidentsAddresses', [])
    const predefinedLossLocations = _.get(claimVM.value, 'predefinedLossLocations');
    
    const updatePropertyIncidents = useCallback((newPropertyIncidents, updatedAddress) => {
        _.set(claimVM.value, `lobs.${LOB_NAME}.fixedPropertyIncidents_Ext`, newPropertyIncidents)

        if (!_.isNil(updatedAddress)) {
            const {
                publicID: updatedAddressID
            } = updatedAddress
            const isExistingClaimAddress = predefinedLossLocations.some((address) => address.publicID === updatedAddressID)
            
            const updatedClaimAddress = isExistingClaimAddress ? predefinedLossLocations.map((address) => {
                if (address.publicID === updatedAddressID) {
                    return updatedAddress
                }
                return address
            }) : predefinedLossLocations.concat([updatedAddress])
            _.set(claimVM.value, 'predefinedLossLocations', updatedClaimAddress)
        }
        updateWizardData(claimVM)
    }, [claimVM, LOB_NAME, updateWizardData, predefinedLossLocations])
    
    const updatePedestrians = useCallback(async (newPedestrians) => {
        const newClaimVM = viewModelService.clone(claimVM);
        _.set(newClaimVM.value, `lobs.${LOB_NAME}.pedestrians_Ext`, newPedestrians)

        setLoadingMask(true);
        await FNOLService.saveFNOLDetails(newClaimVM.value, authHeader)
            .then((response) => {
                claimVM.value = response;
                updateWizardData(claimVM);
            })
            .catch(() => {
                modalApi.showAlert({
                    title: messages.paCreateDraftErrorTitle,
                    message: translator(messages.createDraftErrorMessage, {policytype:displayPolicyType}),
                    status: 'error',
                    icon: 'gw-error-outline',
                    confirmButtonText: commonMessages.ok
                }).catch(_.noop);
                return false;
            });
        setLoadingMask(false);
    }, [claimVM, updateWizardData, LOB_NAME, viewModelService])


    
    const updateRelatedContacts = useCallback((newRelatedContacts) => {
        _.set(claimVM.value, `lobs.${LOB_NAME}.relatedContacts_Ext`, newRelatedContacts)
        updateWizardData(claimVM)
    }, [claimVM, updateWizardData, LOB_NAME, viewModelService])

    const updateRelatedContact = useCallback((updatedRelatedContact) => {
        const {
            publicID
        } = updatedRelatedContact
        const isExistingContact = relatedContacts.some((contact) => contact.publicID === publicID)
        
        const updatedRelatedContacts = isExistingContact ? relatedContacts.map((contact) => {
            if (contact.publicID === publicID) {
                return updatedRelatedContact
            }
            return contact
        }) : relatedContacts.concat([updatedRelatedContact])
        updateRelatedContacts(updatedRelatedContacts)
    }, [relatedContacts, updateRelatedContacts])


    useEffect(() => {
        registerInitialComponentValidation(isClaimStatus);
    }, [registerInitialComponentValidation, isClaimStatus]);

    const overrides = useMemo(() => ({
        '@field': {
            labelPosition: 'left',
            showOptional: true
        },
        lossLocation: {
            path: !_.isUndefined(claimVM) ? claimVM : null,
            updatePath: updateWizardData,
            isShowWhereDidHappenRadioButton: false,
            showErrors: showErrors,
            setComponentValidation: onValidate,
        },
        paPropertyTitle: {
            visible: lossCause === 'collision_EXT'
        },
        propertyIncidents: {
            propertyIncidents: propertyIncidents,
            vehicleIncidents,
            predefinedLossLocations,
            incidentsAddresses,
            updatePropertyIncidents: updatePropertyIncidents,
            visible: lossCause === 'collision_EXT'
        },
        paPedestrianTitle: {
            visible: lossCause === 'collision_EXT'
        },
        pedestrians: {
            pedestrians,
            updatePedestrians,
            relatedContacts,
            updateRelatedContact,
            filteredRelatedContacts,
            visible: lossCause === 'collision_EXT'
        },
        lossDetail: {
            visible: lossSubCauseAvailableValues.length > 0
        },
    }), [updatePropertyIncidents, vehicleIncidents, showErrors]);

    const handleSaveClaimData = useCallback(() => {
        setIsLoading(true);
        return FNOLService.saveFNOLDetails(claimVM.value, authHeader)
            .then((response) => {
                claimVM.value = response;
                return claimVM;
            })
            .catch(() => {
                modalApi.showAlert({
                    title: messages.paCreateDraftErrorTitle,
                    message: translator(messages.createDraftErrorMessage, {policytype:displayPolicyType}),
                    status: 'error',
                    icon: 'gw-error-outline',
                    confirmButtonText: commonMessages.ok
                }).catch(_.noop);
                return false;
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, [FNOLService, authHeader, claimVM, displayPolicyType, modalApi, translator]);

    const onNext = useCallback(async () => {
        setIsLoading(true);
        const policyNumber = _.get(claimVM.value, 'policy.policyNumber');
        const isClaimNumberAvailable = _.get(claimVM.value, 'claimNumber');
        if (isClaimNumberAvailable) {
            return handleSaveClaimData();
        }
        // eslint-disable-next-line no-return-await
        return await FNOLService.getFNOLDetails(policyNumber, claimVM.value, authHeader)
            .then((response) => {
                claimVM.value = new Claim(response);
                updateWizardData(claimVM)
                return claimVM;
            })
            .catch(() => {
                modalApi.showAlert({
                    title: messages.paCreateDraftErrorTitle,
                    message: translator(messages.paCreateDraftErrorMessage, displayPolicyType),
                    status: 'error',
                    icon: 'gw-error-outline',
                    confirmButtonText: commonMessages.ok
                }).catch(_.noop);
                return false;
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, []);

    const readValue = useCallback(
        (id, path) => {
            return readViewModelValue(metadata.pageContent, claimVM, id, path, overrides);
        },
        [claimVM]
    );

    const resolvers = {
        resolveComponentMap: {
            losslocationcomponent: FNOLLossLocation,
            PropertyIncidents: PropertyIncidents,
            Pedestrians: Pedestrians
        }
    };

    return (
        <WizardPage
            skipWhen={initialValidation}
            disableNext={!isComponentValid}
            onNext={isComponentValid ? onNext : setValidation }
            cancelLabel={translator(fnolCommonMessages.fnolSaveandExit)}
            template={WizardPageTemplate} 
            alwaysCallOnNext>
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={claimVM}
                resolveValue={readValue}
                overrideProps={overrides}
                onValidationChange={onValidate}
                onModelChange={updateWizardData}
                componentMap={resolvers.resolveComponentMap}
                showErrors={showErrors}
            />
        </WizardPage>
    );
}

FNOLPAIncidentsPage.propTypes = wizardProps;
FNOLPAIncidentsPage.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func
    }).isRequired
};
export default withRouter(withAuthenticationContext(FNOLPAIncidentsPage));
