
/* eslint no-param-reassign: "error"*/
import _ from 'lodash';
import { error } from '@jutro/logger';
import { EntityUtil } from '@xengage/gw-portals-util-js';
import {FNOLService} from 'gw-capability-fnol'
import PolicyLineExtensionConfiguration from '../PolicyLineExtensionConfiguration';
import Contact from './Contact';
import MetroReport from './MetroReport';
import Official from './Official'


function Claim(data) {
    if (data) {
        data.contacts = _.map(data.contacts, (c) => {
            return new Contact(c);
        });

        if (data.claimReporter) {
            data.claimReporter.reportedBy = this.findContact(data, data.claimReporter.reportedBy);
        }

        data.mainContact = this.findContact(data, data.mainContact);

        _.each(data.relatedContacts, _.bind(function (rc) {
            rc.contact = this.findContact(data, rc.contact);
        }, this));

        _.extend(this, data);
        // Convert loss date to a date object.
        this.lossDate = new Date(this.lossDate).toJSON();
    } else {
        this.lossDate = '';
        this.contacts = [];
        this.relatedContacts = [];
    }
    if (this.policy && !this.lossType) {
        PolicyLineExtensionConfiguration.setLossDetails(this);
    }
    PolicyLineExtensionConfiguration.mixinLobDraftDataExtensions(this);
}

Claim.prototype.nextId = () => {
    return EntityUtil.nextId();
};

Claim.prototype.getLossCategory = function () {
    let category = null;
    if (this.lossType && this.lossCause) {
        switch (this.lossType) {
            case 'AUTO':
                switch (this.lossCause) {
                    case 'theftentire':
                    case 'theftparts':
                        category = 'theft';
                        break;
                    case 'glassbreakage':
                        category = 'glass';
                        break;
                    case 'otherobjcoll':
                        category = 'other';
                        break;
                    default:
                        category = 'collision';
                        break;
                }
                break;

            case 'PR':
                switch (this.lossCause) {
                    case 'fire':
                        category = 'fire';
                        break;
                    case 'waterdamage':
                    case 'mold':
                        category = 'waterdamage';
                        break;
                    case 'burglary':
                    case 'vandalism':
                    case 'riotandcivil':
                        category = 'burglary';
                        break;
                    default:
                        category = 'other';
                }
                break;
            default:
                break;
        }
    }
    return category;
};

Claim.prototype.createContact = function (data) {
    const contactData = _.extend({}, data, { tempID: this.nextId() });
    const newContact = new Contact(contactData);
    this.contacts.push(newContact);
    return newContact;
};

Claim.prototype.createMetroReport = function (data) {
    const metroReportData = _.extend({}, data, { tempID: this.nextId(), country: 'US' });
    const newMetroReport = new MetroReport(metroReportData);
    return newMetroReport;
};

Claim.prototype.createOfficial = function (data) {
    const officialData = _.extend({}, data, { tempID: this.nextId() });
    const newOfficial = new Official(officialData);
    return newOfficial;
};

Claim.prototype.availableContacts = function () {
    return this.contacts;
};

Claim.prototype.availableRelatedContacts = function () {
    return _.filter(this.contacts, function (c) { // eslint-disable-line prefer-arrow-callback
        return _.find(this.relatedContacts, {
            contact: c
        }) === undefined;
    }.bind(this));
};

Claim.prototype.addRelatedContact = function (c) {
    const contact = c || this.createContact();
    this.relatedContacts.push({
        role: 'witness',
        injured: false,
        contact: contact
    });
};

Claim.prototype.addOfficial = function (o) {
    const official = o || this.createOfficial();
    if(!this.officials){
        this.officials = []
    }
    this.officials.push(official);
};

Claim.prototype.addMetroReport = function (o) {
    const metroReport = o ? {...o, country: 'US'} : this.createMetroReport();
    if(!this.metroReports){
        this.metroReports = []
    }
    this.metroReports.push(metroReport);
};

Claim.prototype.removeRelatedContact = function (rc) {
    const idx = _.findIndex(this.relatedContacts, {
        contact: rc
    });
    this.relatedContacts.splice(idx, 1);
};

Claim.prototype.removeofficial = function (official) {
    const idx = _.findIndex(this.officials, official);
    this.officials.splice(idx, 1);
};

Claim.prototype.removeMetroReport = function (metroReport) {
    const idx = _.findIndex(this.metroReports, metroReport);
    this.metroReports.splice(idx, 1);
};

Claim.prototype.changePolicy = function (policy) {
    if (!this.policy || this.policy.policyNumber !== policy.policyNumber) {
        this.policy = policy;
        this.publicID = null;
        this.lossLocation = null;
        this.contacts = [];
        this.mainContact = null;
        this.relatedContacts = [];
        this.contacts = [];
    }
};

Claim.prototype.findContact = function (claim, contact, matchKeysArray) {
    return EntityUtil.findMatchingEntity(claim.contacts, contact, {
        Ctor: Contact,
        keys: matchKeysArray
    });
};

/**
 * 
 * @param {Service} FNOLService 
 * @param {Object} claimValue claimVM.value
 * @param {authHeader} authHeader 
 */
async function saveFNOLDetails(claimValue, authHeader, setLoading = _.noop, errorHandling = _.noop) {
    let updatedClaim = false;
    try {
        setLoading(true)
        updatedClaim = await FNOLService.saveFNOLDetails(claimValue, authHeader)
        setLoading(false)
        return updatedClaim;
    } catch (e) {
        error(e);
        if (errorHandling !== _.noop) {
            return errorHandling(e);
        }
        throw e;
    }
}

export default Claim;
export {
    saveFNOLDetails
}
