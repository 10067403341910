import { defineMessages } from 'react-intl';

export default defineMessages({
    BR_POI_4182: {
        id: 'wni.br.cl.POI4182.This submission has been re-rated due to a mandatory rate change.',
        defaultMessage: 'This submission has been re-rated due to a mandatory rate change.'
    },
    BR_CL_0203: {
        id: 'wni.br.cl.0203.Warning: 25 or more heavy/extra heavy power units. May need loss control inspection.',
        defaultMessage: 'Warning: 25 or more heavy/extra heavy power units. May need loss control inspection.',
    },
    BR_CL_0202: {
        id: 'wni.br.cl.0202.Warning: Experience Mod is 1.25 or above. May need loss control inspection.',
        defaultMessage: 'Warning: Experience Mod is 1.25 or above. May need loss control inspection.',
    },
    BR_CL_0204: {
        id: 'nwi.br.cl.0204.Warning: Review POINT to see if account premium is over $100k.',
        defaultMessage: 'Warning: Review POINT to see if account premium is over $100k.',
    },
    //
    BR_POI_48776: {
        id: 'wni.br.cl.POI48776.You may proceed to modify or add exposures to existing locations. Responses to previously answered underwriting questions are not editable in portal. Please contact your underwriter with any questions.',
        defaultMessage: 'You may proceed to add or modify existing locations, exposures and additional insureds and associated coverages. Responses to previously answered underwriting questions are not editable in portal. Please contact your underwriter with any questions.'
    },
    BR_PolicyChange_Details: {
        id: 'wni.br.cl.PolicyChange.details.Existing underwriting questions from this policies will not be editable. Portal initiated changes will only allow for modifying, adding and deleting Vehicles and Additional Interests',
        defaultMessage: 'Existing underwriting questions from this policies submission will not be editable. Portal initiated changes will only allow for modifying, adding and deleting Vehicles and Additional Interests'
    },
    BR_IM_PolicyChange_Details: {
        id: 'wni.br.cl.PolicyChange.details.IM Policy Change info msg',
        defaultMessage: 'You may proceed to modify or add exposures to existing locations. Responses to previously answered underwriting questions are not editable in portal. Please contact your underwriter with any questions.'
    },
    BR_POI_53097: {
        id: 'wni.br.cl.POI53097.You may proceed to modify or add exposures to existing locations. Responses to previously answered underwriting questions are not editable in portal. Please contact your underwriter with any questions.',
        defaultMessage: 'You may proceed to modify or add exposures to existing locations. Responses to previously answered underwriting questions are not editable in portal. Please contact your underwriter with any questions.'
    },
    BR_POI_0490: {
        id: 'wni.br.cl.0490.Changing TRIA Coverages at the account level will change all subsequent transactions. Any previously bound policies will require a policy change in order to reflect this change.',
        defaultMessage: 'Changing TRIA Coverages at the account level will change all subsequent transactions. Any previously bound policies will require a policy change in order to reflect this change.'
    }
});
