import React, { useState, useEffect, useContext, useCallback } from "react"
import _ from "lodash";
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { useTranslator } from '@jutro/locale';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { useWniModal } from 'wni-components-platform-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useValidation } from '@xengage/gw-portals-validation-react';

import { CPIncidentsDetailService } from 'wni-capability-claim-cp';

import { messages as commonMessages } from '@xengage/gw-platform-translations';
import metadata from './CPVehicleIncidents.metadata.json5';
import messages from '../FNOLCPIncidentDetailsPage.messages';
import CPVehicleDetail from "./VehicleDetail/CPVehicleDetail";
import { getNewContactTempId } from "../utils/NewContactUtil";
import styles from '../FNOLCPIncidentDetailsPage.module.scss';
import { Link } from '@jutro/router';

const CPVehicleIncidents = (props) => {
    const {
        claimVM,
        lobName,
        updateWizardData,
        updateWizardSnapshot,
        relatedContactsPath,
        relatedContacts,
    } = props;

    const {
        claimNumber
    } = claimVM.value;

    const modalApi = useWniModal();
    const translator = useTranslator();
    const { authHeader } = useAuthentication();
    
    const { loadingMask: { setLoadingMask } } = useDependencies('loadingMask');
    const viewModelService = useContext(ViewModelServiceContext);
    const [currentRow, updateCurrentRowInteranl] = useState(null);
    const [selection, updateSelection] = useState([]);
    const [vehicleInvolvedValue, updateVehicleInvolvedValue] = useState(false);
    const [vehicleIncidentsData, updateVehicleIncidentsData] = useState([]);
    const [currentRowIndex, updateCurrentRowIndex] = useState(-1);
    const [bakSelectedIncident, setBakSelectedIncident] = useState({});
 
    const vehicleIncidentsPath = `lobs.${lobName}.vehicleIncidents_Ext`;
    const vehicleIncidentsVM = _.get(claimVM, vehicleIncidentsPath);
    const vehicleIncidents = _.get(claimVM.value, vehicleIncidentsPath, []);

    const {
        onValidate,
    } = useValidation('CPVehicleIncidents');
    const [showErrors, setShowErrors] = useState(false);

    useEffect(() => {
        updateVehicleIncidentsData(vehicleIncidents);
        updateVehicleInvolvedValue(!_.isEmpty(vehicleIncidents));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const updateClaim = (currentVM) => {
        const publicID = _.get(currentVM.value, 'publicID');
        const allVehicles = _.get(claimVM.value, vehicleIncidentsPath);
        const currentIndex = allVehicles.findIndex((item) => item.publicID === publicID); 
        const newClaimVM = viewModelService.clone(claimVM);
        _.set(newClaimVM.value, `${vehicleIncidentsPath}[${currentIndex}]`, currentVM.value);
        return newClaimVM;
    };

    const updateCurrentRow = (rowData, updateClaimData) => {
        if(!rowData) {
            updateCurrentRowInteranl(rowData);
            return false;
        }
        updateCurrentRowInteranl(rowData);
        if(updateClaimData) {
            const newClaimVM = updateClaim(rowData);
            updateClaimData(newClaimVM)
        }
    };

    const syncWizardData = (currentVM) => {
        updateCurrentRow(currentVM, updateWizardData);
    };

    const syncWizardDataSnapshot = (currentVM) => {
        updateCurrentRow(currentVM, updateWizardSnapshot);
    };

    const updateClaimVMForResponse = (res) => {
        _.set(claimVM.value, vehicleIncidentsPath, res.vehicleIncidents);
        _.set(claimVM.value, 'initalVehicleIncidentData_Ext', res.initalVehicleIncidentData);
        _.set(claimVM.value, relatedContactsPath, res.relatedContacts);
        updateWizardSnapshot(claimVM);
        updateWizardData(claimVM);
        updateSelection([]);
        updateVehicleIncidentsData(res.vehicleIncidents);
        updateCurrentRowIndex(-1);
    };

    const handleAddVehicle = async () => {
        const {
            _dtoName,
            _xCenter,
        } = vehicleIncidentsVM;
        setLoadingMask(true);
        const newVehicleIncident = await CPIncidentsDetailService.createNewVehicleIncident(
            claimNumber,
            authHeader
        );
        setLoadingMask(false);
        // update isSave
        _.set(newVehicleIncident.value, 'isSave', true);
        const vehicleIncidentVM = viewModelService.create(newVehicleIncident, _xCenter, _dtoName);
        vehicleIncidentsVM.pushElement(vehicleIncidentVM);
        updateCurrentRow(vehicleIncidentVM);
        updateWizardData(claimVM);
        updateVehicleIncidentsData(vehicleIncidentsVM.value);
        updateCurrentRowIndex(vehicleIncidentsVM.length - 1);
        const bakNewIncident = _.cloneDeep(vehicleIncidentVM.value);
        _.set(bakNewIncident, 'isNew', true);
        setBakSelectedIncident(_.cloneDeep(bakNewIncident));
    }

    const handleDeleteVehicle = () => {
        modalApi.showConfirm({
            title: messages.removeIncidentTitle,
            message: messages.removeIncident,
            status: 'warning',
            icon: 'gw-error-outline'
        }).then(async(result) => {
            if (result === 'cancel' || result === 'close') {
                return _.noop();
            }
            const selectedInjuryIncidents = [];
            selection.forEach((rowIndex) => {
                selectedInjuryIncidents.push(vehicleIncidentsData[rowIndex]);
            })
            const vehicleIncidentPublicIDs = selectedInjuryIncidents.map((item) => item.publicID);           
            setLoadingMask(true);
            const res = await CPIncidentsDetailService.removeVehicleIncident(
                claimNumber,
                vehicleIncidentPublicIDs,
                authHeader
            );
            setLoadingMask(false);
            syncWizardData(null);
            updateClaimVMForResponse(res);
            return true;
        }, _.noop);
    }

    const cancelVehicle = async () => {
        if (bakSelectedIncident.isNew) {
            // new vehicle
            const currentVehicleIncident = vehicleIncidents[currentRowIndex];
            const vehicleIncidentPublicIDs = [currentVehicleIncident.publicID];           
            setLoadingMask(true);
            const res = await CPIncidentsDetailService.removeVehicleIncident(
                claimNumber,
                vehicleIncidentPublicIDs,
                authHeader
            );
            setLoadingMask(false);
            syncWizardData(null);
            updateClaimVMForResponse(res);
        } else {
            // edit vehicle
            vehicleIncidentsVM.value.splice(currentRowIndex, 1, bakSelectedIncident);
            updateVehicleIncidentsData(vehicleIncidentsVM.value);
            updateCurrentRowInteranl(null);
            updateCurrentRowIndex(-1);
        }
    }

    const editInjury = (item) => {
        const currentIndex = vehicleIncidents.findIndex((incident) => incident.publicID === item.publicID);
        const childrenVM = _.get(claimVM, `${vehicleIncidentsPath}.children`);
        const injuryVM = childrenVM.find((child) => child.value.publicID === item.publicID);   
        // update isSave
        _.set(injuryVM.value, 'isSave', true);    
        syncWizardData(injuryVM);
        updateCurrentRowIndex(currentIndex);
        setBakSelectedIncident(_.cloneDeep(injuryVM.value));
    };

    const saveAndContinue = () => {
        // update relatedContact
        const ownerContactExt = _.get(currentRow.value, 'vehicleOwner');
        _.set(ownerContactExt, 'updated_Ext', true);
        let newRelatedContacts = _.cloneDeep(relatedContacts);
        if(ownerContactExt) {
            const isNewContact = !relatedContacts.some((contact) => contact.publicID === ownerContactExt.publicID);
            const ownerContactIndex = relatedContacts.findIndex((contact) => contact.publicID === ownerContactExt.publicID);
            if(isNewContact) {
                const tempContactID = getNewContactTempId();
                const newRelatedContact = {
                    ...ownerContactExt,
                    onwerPublicID_Ext: tempContactID
                }
                newRelatedContacts = relatedContacts.concat([newRelatedContact]);
            } else {
                newRelatedContacts = relatedContacts.toSpliced(ownerContactIndex, 1, ownerContactExt);
            }
            _.set(claimVM.value, relatedContactsPath, newRelatedContacts);
        }
        setLoadingMask(true);
        CPIncidentsDetailService.updateVehicleIncident(
            claimNumber,
            currentRow.value,
            newRelatedContacts,
            authHeader
        )
        .then((res) => {
            updateClaimVMForResponse(res);
            setShowErrors(false);
            updateCurrentRow(null);
        })
        .catch(() => {
            modalApi.showAlert({
                title: messages.createDraftErrorTitle,
                message: translator(messages.createDraftErrorMessage),
                status: 'error',
                icon: 'gw-error-outline',
                confirmButtonText: commonMessages.ok
            }).catch(_.noop);
            return false;
        })
        .finally(() => {
            setLoadingMask(false);
        });
    };

    const onVehicleIncidentToggleChange = useCallback((newValue) => {
        if (newValue || vehicleIncidentsData.length === 0) {
            updateVehicleInvolvedValue(newValue);
        }
    }, [vehicleIncidentsData]);

    const renderEditCell =  (item) => {
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        return <Link className={styles.editCell} onClick={() => editInjury(item)}>{translator(messages.edit)}</Link>
    };

    const writeValue = (value, path) => {
        if(currentRow) {
            _.set(currentRow.value, path, value);
            syncWizardData(currentRow);
        }
    };

    const overrides = {
        '@field': {
            labelPosition: 'left',
        },
        cpVehicleIncidentQuestion: {
            value: vehicleInvolvedValue,
            onValueChange: onVehicleIncidentToggleChange
        },
        cpVehicleIncidentsTableContainer: {
            visible: vehicleInvolvedValue
        },
        cpVehicleIncidentsTable: {
            data: vehicleIncidentsData,
            selectedRows: selection,
            onSelectionChange: (rows) => updateSelection(rows)
        },
        deleteVehicles: {
            disabled: selection.length === 0
        },
        addVehicle: {
            disabled: !_.isEmpty(currentRow)
        },
        vehicleDetailContainer: {
            visible: !_.isEmpty(currentRow),
        },
        vehicleDetails: {
            vehicleVM: currentRow,
            relatedContacts,
            onValueChange: writeValue,
            syncWizardData,
            syncWizardDataSnapshot,
            onDetailSave: saveAndContinue,
            onDetailCancel: cancelVehicle
        }
    };
   
    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            renderEditCell,
            handleDeleteVehicle,
            handleAddVehicle
        },
        resolveComponentMap: {
            vehicleDetailComponent: CPVehicleDetail
        }
    };

    const readValue = (fieldId, fieldPath) => {
        return readViewModelValue(
            metadata.componentContent, claimVM, fieldId, fieldPath, overrides
        );
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={claimVM}
            overrideProps={overrides}
            onValueChange={writeValue}
            onValidationChange={onValidate}
            resolveValue={readValue}
            classNameMap={resolvers.resolveClassNameMap}
            callbackMap={resolvers.resolveCallbackMap}
            componentMap={resolvers.resolveComponentMap}
            showErrors={showErrors}
        />
    );
}

export default CPVehicleIncidents;
