import { setComponentMapOverrides } from '@jutro/legacy/uiconfig';

import FNOLIMWizard from './FNOLIMWizard.pe';
import IMLossTypePage from './pages/LossType/FNOLIMLossTypePage.pe';
import IMIncidentsPage from './pages/IncidentDetail/FNOLIMIncidentsPage.pe';
import IMBusnessLocationPage from './pages/BusinessLocation/FNOLIMBusnessLocationPage.pe';

setComponentMapOverrides(
    {
        FNOLWCWizard: { component: 'FNOLIMWizard' },
        IMLossTypePage: { component: 'IMLossTypePage' },
        IMIncidentsPage: { component: 'IMIncidentsPage' },
        IMBusnessLocationPage: { component: 'IMBusnessLocationPage' },
    },
    {
        FNOLIMWizard,
        IMLossTypePage,
        IMIncidentsPage,
        IMBusnessLocationPage,
    }
);

export {
    FNOLIMWizard as default,
    IMLossTypePage as FNOLIMLossTypePage,
    IMIncidentsPage as FNOLIMIncidentDetailsPage,
    IMBusnessLocationPage as FNOLIMBusnessLocationPage
};
