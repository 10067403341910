import React, { useState, useCallback, useEffect } from 'react';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import { useTranslator } from '@jutro/locale';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { WizardPage, wizardProps } from '@xengage/gw-portals-wizard-react';
import { WizardPageTemplate } from 'gw-portals-wizard-components-ui';
import { FNOLLossLocation, fnolCommonMessages, ClaimUtil } from 'gw-capability-fnol-common-react';
import { isCapabilityEnabled } from '@xengage/gw-portals-config-js';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
// eslint-disable-next-line import/no-unresolved
import appConfig from 'app-config';
import { DateUtil } from '@xengage/gw-portals-util-js';


import messages from '../../../FNOLPA.messages';
import metadata from './TheftDetailsPage.metadata.json5';
import './TheftDetailsPage.messages';
import wizardConfig from '../../../config/fnol-pa-wizard-config.json5';

function FNOLPATheftDetailsPage(props) {
    const {
        wizardData: claimVM,
        updateWizardData,
        changeNextSteps,
        currentStepIndex,
        history: {
            location: { state = {} }
        }
    } = props;

    const LOB_NAME = ClaimUtil.getLobByPolicyType(_.get(claimVM, 'value.policy.policyType', 'personalAuto'));

    const { steps: wizardSteps } = wizardConfig;
    const { capabilitiesConfig } = appConfig;
    const translator = useTranslator();
    const [isVehicleInvolve, setVehicleInvolve] = useState('');
    const [availableYears, updateAvailableYears] = useState([]);
    const {
        onValidate,
        isComponentValid,
        initialValidation,
        registerInitialComponentValidation,
        registerComponentValidation
    } = useValidation('TheftDetailsPage');
    const newVehicle = {
        placeholder: true
    };

    const onChangeNextSteps = useCallback(() => {
        const lossCauseCheck = _.get(claimVM.value, 'lossCause');
        const nextSteps = wizardSteps.slice(currentStepIndex + 1, wizardSteps.length);
        const vehiclePageIndex = 0;
        if (lossCauseCheck === 'theftentire') {
            nextSteps.splice(vehiclePageIndex, 2);
        }
        if (lossCauseCheck === 'theftparts') {
            nextSteps.splice(vehiclePageIndex, 1);
        }
        changeNextSteps(nextSteps);
        return claimVM;
    }, [claimVM, wizardSteps, currentStepIndex, changeNextSteps]);

    useEffect(() => {
        let nextSteps = wizardSteps.slice(currentStepIndex + 1, wizardSteps.length);
        if (!isCapabilityEnabled({ capabilitiesConfig, capabilityName: 'vendor' })) {
            nextSteps = nextSteps.filter((step) => step.path !== '/type-of-repair');
        }
        changeNextSteps(nextSteps);
        if (_.isEmpty(_.get(claimVM, `lobs.${LOB_NAME}.vehicleIncidents.value`))) {
            if (_.get(claimVM, `lobs.${LOB_NAME}.vehicleIncidents.length`) === 0) {
                _.get(claimVM, `lobs.${LOB_NAME}.value`, { createVehicleIncident: _.noop }).createVehicleIncident()
            }
        }
        const currentYear = DateUtil.currentDate().getUTCFullYear();
        const years = [];
        const { oldestCarYear } = appConfig.personalAutoConfig;
        for (let i = currentYear; i >= oldestCarYear; i -= 1) {
            years.push({
                code: i,
                name: i
            });
        }
        updateAvailableYears(years);
        const theftCodes = {
            theftentire: _.find(claimVM.lossCause.aspects.availableValues, {
                code: 'theftentire'
            }),
            theftparts: _.find(claimVM.lossCause.aspects.availableValues, {
                code: 'theftparts'
            })
        };
        _.set(
            claimVM.value,
            `lobs.${LOB_NAME}.vehicleIncidents.theft`,
            _.values(theftCodes).includes(claimVM.lossCause.value)
        );
        _.set(claimVM.value, `lobs.${LOB_NAME}.vehicleIncidents.safeToDrive`, false);
        updateWizardData(claimVM);
        onChangeNextSteps();
        // Call once when page is mount
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getAvalailableVehicles = useCallback(() => {
        const selectedVehicle = _.get(
            claimVM,
            `lobs.${LOB_NAME}.vehicleIncidents.value[0].vehicle`
        );
        if (!_.isEmpty(_.get(claimVM, `claimVM.lobs.${LOB_NAME}.vehicleIncidents.value[0]`))) {
            const vehicleIncidents = _.get(claimVM, `lobs.${LOB_NAME}.vehicleIncidents.value[0]`, { availableVehicles: _.noop });
            const updatedAvailableVehicles = vehicleIncidents.availableVehicles(
                    _.get(claimVM, `lobs.${LOB_NAME}.value`),
                    selectedVehicle
                );
            updatedAvailableVehicles.push(newVehicle);

            // if there is a new vehicle added pass it in to list of available vehicles
            if (
                !_.isEmpty(_.get(claimVM, `lobs.${LOB_NAME}.vehicleIncidents.children[0].vehicle.value`))
                && !updatedAvailableVehicles.includes(
                    _.get(claimVM, `lobs.${LOB_NAME}.vehicleIncidents.children[0].vehicle.value`)
                )
            ) {
                updatedAvailableVehicles.push(
                    _.get(claimVM, `lobs.${LOB_NAME}.vehicleIncidents.children[0].vehicle.value`)
                );
            }

            const updatedVehicleArray = updatedAvailableVehicles.map((value) => {
                const val = value.placeholder
                    ? translator(messages.paVehicleOtherVehicle)
                    : value.getDisplayName(translator(messages.paVehicleNewVehicle));
                return {
                    code: val,
                    name: val
                };
            });
            return updatedVehicleArray;
        }
        return [];
    }, [LOB_NAME, claimVM, newVehicle, translator]);

    const handleVehicleSelect = useCallback(
        (value) => {
            if (value === translator(messages.paVehicleOtherVehicle)) {
                const vehicleIncidentPath = `lobs.${LOB_NAME}.vehicleIncidents.value[0]`;
                const vehicleIncident = _.get(claimVM, vehicleIncidentPath);
                vehicleIncident.setNewVehicle(claimVM.value);
                setVehicleInvolve(translator(messages.paVehicleNewVehicle));
                updateWizardData(claimVM);
            } else {
                const vehiclesItems = _.get(claimVM, `lobs.${LOB_NAME}.vehicles.value`, []);
                const selectedVehicle = vehiclesItems.find((vehicle) => {
                    const displayName = vehicle.getDisplayName();
                    return displayName === value;
                });
                if (!_.isEmpty(selectedVehicle)) {
                    _.set(
                        claimVM,
                        `lobs.${LOB_NAME}.vehicleIncidents.value[0].vehicle`,
                        selectedVehicle
                    );
                    setVehicleInvolve(selectedVehicle.getDisplayName());
                }
                updateWizardData(claimVM);
            }
        },
        [translator, LOB_NAME, claimVM, updateWizardData]
    );

    const getLossCause = useCallback(
        (selectedLossCause) => {
            _.set(claimVM, 'lossCause.value', selectedLossCause);
            updateWizardData(claimVM);
        },
        [claimVM, updateWizardData]
    );

    const overrideProps = {
        '@field': {
            labelPosition: 'left',
            showOptional: true,
            phoneWide: {
                labelPosition: 'top'
            }
        },
        vehicleInvolve: {
            availableValues: getAvalailableVehicles(),
            value: isVehicleInvolve,
            showOptional: false
        },
        whatStolen: {
            value: _.get(claimVM.value, 'lossCause')
        },
        paVehicleOtherVehicleSection: {
            visible:
                !_.isEmpty(
                    _.get(claimVM, `lobs.${LOB_NAME}.vehicleIncidents.children[0].vehicle.value`)
                )
                && !_.get(
                    claimVM,
                    `lobs.${LOB_NAME}.vehicleIncidents.children[0].vehicle.policyVehicle.value`
                )
        },
        paVehicleOtherVehicleYear: {
            availableValues: availableYears,
            value: _.get(
                claimVM,
                `lobs.${LOB_NAME}.vehicleIncidents.children[0].vehicle.year.value`
            )
        },
        lossLocationComponent: {
            path: !_.isUndefined(claimVM) ? claimVM : null,
            updatePath: updateWizardData
        }
    };

    const readValue = useCallback(
        (id, path) => {
            return readViewModelValue(metadata.pageContent, claimVM, id, path, overrideProps);
        },
        [claimVM, overrideProps]
    );

    const resolvers = {
        resolveCallbackMap: {
            handleVehicleSelect: handleVehicleSelect,
            getLossCause: getLossCause,
            onValidate: onValidate
        },
        resolveComponentMap: {
            losslocationcomponent: FNOLLossLocation
        }
    };

    const isClaimStatus = useCallback(() => {
        const { claimStatus } = state;
        const lossCauseCheck = _.get(claimVM.value, 'lossCause');
        return !_.isEmpty(claimStatus) && lossCauseCheck === 'theftentire';
    }, [claimVM.value, state]);

    const validateVehicleInvolve = useCallback(() => {
        return !_.isEmpty(isVehicleInvolve);
    }, [isVehicleInvolve]);

    useEffect(() => {
        registerInitialComponentValidation(isClaimStatus);
        registerComponentValidation(validateVehicleInvolve);
    }, [
        registerComponentValidation,
        validateVehicleInvolve,
        registerInitialComponentValidation,
        isClaimStatus,
        isVehicleInvolve
    ]);

    return (
        <WizardPage
            cancelLabel={translator(fnolCommonMessages.fnolSaveandExit)}
            skipWhen={initialValidation}
            template={WizardPageTemplate}
            disableNext={!isComponentValid}
            onNext={onChangeNextSteps}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={claimVM}
                overrideProps={overrideProps}
                onModelChange={updateWizardData}
                callbackMap={resolvers.resolveCallbackMap}
                resolveValue={readValue}
                componentMap={resolvers.resolveComponentMap}
                classNameMap={resolvers.resolveClassNameMap}
                onValidationChange={onValidate}
            />
        </WizardPage>
    );
}

FNOLPATheftDetailsPage.propTypes = wizardProps;
export default withRouter(FNOLPATheftDetailsPage);
