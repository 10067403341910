import React, { useCallback, useState, useEffect } from 'react';
import _ from 'lodash';
import { useModal } from '@jutro/components';
import { CheckboxField } from '@jutro/legacy/components';
import {
    WizardPage,
    wizardProps
} from '@xengage/gw-portals-wizard-react';
import { WizardPageTemplate } from 'gw-portals-wizard-components-ui';
import { useTranslator } from '@jutro/locale';
import { fnolCommonMessages } from 'gw-capability-fnol-common-react';
import { withAuthenticationContext } from '@xengage/gw-digital-auth-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { withRouter } from 'react-router-dom';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { messages as commonMessages } from '@xengage/gw-platform-translations';

import { FNOLService } from 'wni-capability-fnol';

import metadata from './FNOLCPBusinessLocationPage.metadata.json5';
import messages from '../../FNOLCP.messages';

function FNOLCPBusinessLocationPage(props) {
    const modalApi = useModal();
    const {
        wizardData: claimVM,
        updateWizardData,
        authHeader
    } = props;
    const translator = useTranslator();
    const { loadingMask: { setLoadingMask } } = useDependencies('loadingMask');
    // const { FNOLService } = useDependencies('FNOLService');
    const [totalBuildings, updateTotalBuildings] = useState([]);
    const [policyRiskUnits, setPolicyRiskUnits] = useState([]);
    const {
        onValidate,
        isComponentValid,
        initialValidation,
        registerComponentValidation
    } = useValidation('FNOLCPBusinessLocationPage');

    const isSelectedBuildings = useCallback(() => {
        if (totalBuildings.length === 0) {
            return null;
        }
        const selectedBuildings = _.filter(totalBuildings, (item) => item.documentisCheck);
        if (selectedBuildings.length > 0) {
            return true;
        }
        return false;
    }, [totalBuildings]);

    const getResponseData = useCallback((responseData, oldClaimVM) => {
        const addressData = responseData.lobs.commercialProperty;
        const contactsInfo = addressData.propertyRiskUnits.map((building) => {
            const selected = oldClaimVM.policy.lobs
                .commercialProperty.propertyRiskUnits.value.findIndex(
                    (unit) => unit.policySystemId === building.policySystemId
                ) > -1;
            const addressline2 = building.addressline2 ? `, ${building.addressline2}` : '';
            const buildingAddress = `${building.addressLine1} ${addressline2}`;
            return {
                documentCheckbox: building,
                documentBuilding: building.buildingNumber,
                documentDescription: building.description,
                documentCity: building.city,
                documentState: building.state_Ext,
                documentLocation: buildingAddress,
                documentPropertyNo: building.propertyNumber_Ext,
                documentLocationId: building.policySystemId,
                documentisCheck: selected
            };
        });
        return _.sortBy(contactsInfo, ['documentPropertyNo']);
    }, []);

    const getPolicySummaryRiskUnitsFromService = useCallback(() => {
        if (!claimVM.policy.lobs.commercialProperty) {
            claimVM.policy.lobs.value = { commercialProperty: { propertyRiskUnits: [] } };
        }
        const oldClaimVM = _.cloneDeep(claimVM);
        const {
            lossDate,
            policy: {
                policyNumber,
                policySubType_EXT: policySubType,
                policyType_Ext: policyType
            }
        } = claimVM.value;
        const policySearchCriteria = {
            policyNumber: policyNumber,
            lossDate: lossDate,
            policySubType_EXT: policySubType,
            policyType: policyType
        };
        setLoadingMask(true);
        return FNOLService.getPolicySummaryRiskUnitsBySearchCriteria(policySearchCriteria, authHeader).then(
            (response) => {
                setLoadingMask(false);
                setPolicyRiskUnits(response);
                const contactdatatable = getResponseData(response, oldClaimVM);
                updateTotalBuildings([...contactdatatable]);
            }
        );
    }, [FNOLService, authHeader, claimVM, getResponseData, setLoadingMask]);

    useEffect(() => {
        registerComponentValidation(isSelectedBuildings);
    }, [isSelectedBuildings, registerComponentValidation]);

    useEffect(() => {
        getPolicySummaryRiskUnitsFromService();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleClick = useCallback(
        (value, contact) => {
            const selectedIndex = totalBuildings.indexOf(contact);
            totalBuildings[selectedIndex].documentisCheck = value;
            updateTotalBuildings([...totalBuildings]);
            const locationList = _.get(
                claimVM,
                'policy.lobs.commercialProperty.propertyRiskUnits.value'
            );
            const riskUnitLocationList = _.get(
                policyRiskUnits,
                'lobs.commercialProperty.propertyRiskUnits'
            );
            const index = riskUnitLocationList.findIndex((unit) => {
                return unit.policySystemId === contact.documentLocationId;
            });
            if (value) {
                locationList.push(riskUnitLocationList[index]);
                _.set(
                    claimVM,
                    'policy.lobs.commercialProperty.propertyRiskUnits.value',
                    locationList
                );
            } else {
                const updatedList = locationList.splice(index, 0);
                _.set(
                    claimVM,
                    'policy.lobs.commercialProperty.propertyRiskUnits.value',
                    updatedList
                );
            }
            updateWizardData(claimVM);
        },
        [claimVM, totalBuildings, policyRiskUnits, updateWizardData]
    );

    const getDataCell = useCallback((item, index, { path: property }) => {
        return item[property];
    }, []);

    const getCheckboxDataCell = useCallback(
        (item, index) => {
            return (
                <CheckboxField
                    name={item.documentCheckbox.policySystemId}
                    id={item.documentCheckbox.policySystemId}
                    value={item.documentisCheck}
                    onValueChange={(e) => handleClick(e, item, index)}
                />
            );
        },
        [handleClick]
    );

    const overrides = {
        '@field': {
            labelPosition: 'left',
            showOptional: true
        },
        cpBuildingDataTable: {
            data: totalBuildings
        }
    };

    const resolvers = {
        resolveCallbackMap: {
            getCheckboxDataCell: getCheckboxDataCell,
            getDataCell: getDataCell
        }
    };

    const onNext = useCallback(() => {
        const modifiedBuildingsArray = _.filter(totalBuildings, (item) => item.documentisCheck);
        if (modifiedBuildingsArray.length === 0) {
            return false;
        }
        const buildings = _.get(claimVM.value, 'policy.lobs.commercialProperty.propertyRiskUnits');
        const policyNumber = _.get(claimVM.value, 'policy.policyNumber');
        const selectedBuildings = buildings.filter((building) => {
            return modifiedBuildingsArray.some((modifiedBuilding) => {
                return building.policySystemId === modifiedBuilding.documentCheckbox.policySystemId;
            });
        });
        _.set(claimVM.value, 'policy.lobs.commercialProperty.propertyRiskUnits', selectedBuildings);
        setLoadingMask(true);
        return FNOLService.getFNOLDetails(policyNumber, claimVM.value, authHeader)
            .then((response) => {
                claimVM.value = response;
                return claimVM;
            })
            .catch(() => {
                modalApi.showAlert({
                    title: messages.cpCreateDraftErrorTitle,
                    message: messages.cpCreateDraftErrorMessage,
                    status: 'error',
                    icon: 'mi-error-outline',
                    confirmButtonText: commonMessages.ok
                }).catch(_.noop);
                return false;
            })
            .finally(() => {
                setLoadingMask(false);
            });
    }, [totalBuildings, claimVM, setLoadingMask, FNOLService, authHeader, modalApi]);


    return (
        <WizardPage
            cancelLabel={translator(fnolCommonMessages.fnolSaveandExit)}
            template={WizardPageTemplate}
            onNext={onNext}
            disableNext={!isComponentValid}
            skipWhen={initialValidation}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={claimVM}
                overrideProps={overrides}
                onValidationChange={onValidate}
                callbackMap={resolvers.resolveCallbackMap}
                onModelChange={updateWizardData}
            />
        </WizardPage>
    );
}

FNOLCPBusinessLocationPage.propTypes = wizardProps;
export default withRouter(withAuthenticationContext(FNOLCPBusinessLocationPage));
