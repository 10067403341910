import { useModal } from '@jutro/components';
import { BreakpointTrackerContext } from '@jutro/layout';
import { useTranslator } from '@jutro/locale';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { useValidation } from '@xengage/gw-portals-validation-react';
import {
ViewModelForm,
ViewModelServiceContext
} from '@xengage/gw-portals-viewmodel-react';
import { WizardPage,wizardProps, } from '@xengage/gw-portals-wizard-react';
// eslint-disable-next-line import/no-unresolved
import config from 'app-config';
import { fnolCommonMessages, saveFNOLDetails, ClaimUtil } from 'gw-capability-fnol-common-react';
import { WizardPageTemplate } from 'gw-portals-wizard-components-ui';

import _ from 'lodash';
import PropTypes from 'prop-types';
import React,{ useCallback, useContext,useEffect,useMemo,useState } from 'react';
import { withRouter } from 'react-router-dom';

import { PAVehicleService } from 'wni-capability-claim-pa';
import { WTWatercraftService } from 'wni-capability-claim-wt';

import { ProductUtil } from 'wnipe-portals-util-react';

import Contact from './components/Contact/Contact';
import PolicyVehicle from './components/PolicyVehicle/PolicyVehicle';
import VehicleIncident from './components/VehicleIncident/VehicleIncident';
import messages from './FNOLPAVehiclesPage.messages';
import metadata from './FNOLPAVehiclesPage.metadata.json5';

const {
    PA_LOB_NAME,
    WT_LOB_NAME,
    RT_LOB_NAME
} = ProductUtil;

function FNOLPAVehiclesPage(props) {
    const modalApi = useModal();
    const breakpoint = useContext(BreakpointTrackerContext);
    const translator = useTranslator();
    const {
        wizardData: claimVM,
        updateWizardData,
        updateWizardSnapshot,
        isReadOnly = false
    } = props;
    const { oldestCarYear } = config.personalAutoConfig;
    const viewModelService = useContext(ViewModelServiceContext);
    const { authHeader } = useAuthentication();
    const LOB_NAME = ClaimUtil.getLobByPolicyType(_.get(claimVM, 'value.policy.policyType', PA_LOB_NAME));
    const PAGE_TYPE_NAME = LOB_NAME === WT_LOB_NAME ? 'watercraft' : 'vehicle';
    const VehicleService = LOB_NAME === WT_LOB_NAME ? WTWatercraftService : PAVehicleService;
    const {
        loadingMask: { setLoadingMask },
    } = useDependencies('loadingMask');
    const {
        claimNumber,
        lobs: {
            [LOB_NAME]: {
                vehicles_Ext: vehicles,
                vehicleIncidents_Ext: vehicleIncidents,
                thirdPartyIncidents_Ext: thirdPartyIncidents,
                initalVehicleIncidentData_Ext: initalVehicleIncidentData
            },
        },
    } = claimVM.value;
    const [involvedValue, updateInvolvedValue] = useState(false);
    const [initWizardData, updateInitWizardData] = useState();
    const [initIncidentData, updateInitIncidentData] = useState(_.cloneDeep(initalVehicleIncidentData));
    const [isEditing, updateIsEditing] = useState(false);
    const [availableDrivers, updateAvailableDrivers] = useState(null);
    const [availablePassengers, updateAvailablePassengers] = useState(null);

    const {
        isComponentValid,
        initialValidation,
        onValidate,
        registerComponentValidation,
    } = useValidation('VehiclesPage');

    useEffect(() => {
        const involved = !_.isEmpty(thirdPartyIncidents);
        updateInvolvedValue(involved);
        // keep initial wizard data
        updateInitWizardData(viewModelService.clone(claimVM));
    }, []);

    useEffect(() => {
        const newData = _.get(claimVM.value, `lobs.${LOB_NAME}.initalVehicleIncidentData_Ext`);
        updateInitIncidentData(_.cloneDeep(newData));
    }, [LOB_NAME, claimVM]);

    const resetWizardDataToSnapshot = useCallback(() => {
        const newClaimVM = viewModelService.clone(initWizardData);
        updateWizardSnapshot(newClaimVM);
        if (!isReadOnly) {
            updateWizardData(newClaimVM);
        }
    },[initWizardData, isReadOnly, updateWizardData, updateWizardSnapshot, viewModelService]);

    const syncWizardDataSnapshot = (response, basePath) => {
        const newClaimVM = viewModelService.clone(claimVM);
        _.set(newClaimVM.value, `lobs.${LOB_NAME}.${basePath}_Ext`, response[basePath]);
        _.set(newClaimVM.value, `lobs.${LOB_NAME}.initalVehicleIncidentData_Ext`, response.initalVehicleIncidentData);
        if (!isReadOnly) {
            updateWizardSnapshot(newClaimVM);
        }
    };
    const syncWizardData = (data, basePath) => {
        const newClaimVM = viewModelService.clone(claimVM);
        _.set(newClaimVM.value, `lobs.${LOB_NAME}.${basePath}_Ext`, data);
        if (!isReadOnly) {
            updateWizardData(newClaimVM);
        }
    };

    const syncVehicleData = (res) => {
        const vehiclePageData = _.get(claimVM.value, `lobs.${LOB_NAME}`, {});
        const newResponse = {
            ...vehiclePageData,
            vehicles_Ext: res.vehicles,
            vehicleIncidents_Ext: res.vehicleIncidents,
            thirdPartyIncidents_Ext: res.thirdPartyIncidents,
            initalVehicleIncidentData_Ext: res.initalVehicleIncidentData
        };
        const newClaimVM = viewModelService.clone(claimVM);
        _.set(newClaimVM.value, `lobs.${LOB_NAME}`, newResponse);
        updateWizardSnapshot(newClaimVM);
        return newClaimVM;
    }

    const onPageNext = async () => {
        setLoadingMask(true);
        claimVM.value = await saveFNOLDetails(claimVM.value, authHeader)
        const res = await VehicleService.onPageNext(claimNumber, authHeader);
        const newClaimVM = syncVehicleData(res)
        setLoadingMask(false);
        return newClaimVM;
    };

    const saveIncident = async (basePath, rowValue) => {
        setLoadingMask(true);
        const res = await VehicleService.saveVehicleIncident(
            claimNumber,
            rowValue,
            authHeader
        );
        setLoadingMask(false);
        syncWizardDataSnapshot(res, basePath);
    };

    const handleContactSelectionChange = useCallback((ids, type, rowData, updateSelection) => {
        const rowPassengerOptions = _.get(rowData, 'value.passengerMap');
        const rowDriverOptions = _.get(rowData, 'value.driverMap');
        const rowDriver = _.get(rowData, 'value.driver');
        const rowPassengers = _.get(rowData, 'value.passengers');
        let allContacts = _.uniqBy(_.concat(rowPassengerOptions, rowDriverOptions, rowDriver, rowPassengers), 'rowIdPath');
        allContacts = _.filter(allContacts, (contact) => !_.isEmpty(contact));
        if (type === 'driver') {
            updateSelection(ids);
            const newPassengerList = _.filter(allContacts, passenger => !_.includes(ids, passenger.rowIdPath));

            // update the available passengers
            updateAvailablePassengers(newPassengerList);
        } else {
            updateSelection(ids);
            const newDriverList = _.filter(allContacts, driver => !_.includes(ids, driver.rowIdPath));

            // update the available drivers
            updateAvailableDrivers(newDriverList);
        }
    }, []);

    const overrides = useMemo(
        () => ({
            '@field': {
                labelPosition: 'left'
            },
            '@element': {
                claimVM,
                claimNumber,
                authHeader,
                syncWizardData,
                syncWizardDataSnapshot,
                resetWizardDataToSnapshot,
                syncVehicleData,
                saveIncident,
                VehicleService,
                LOB_NAME,
                PAGE_TYPE_NAME,
                initIncidentData,
                handleContactSelectionChange,
                availableDrivers,
                updateAvailableDrivers,
                availablePassengers,
                updateAvailablePassengers,
                updateIsEditing,
                isEditing
            },
            vehicleContainer: {
                basePath: `vehicles`,
                tableData: vehicles
            },
            insuredIncidentContainer: {
                basePath: 'vehicleIncidents',
                tableData: vehicleIncidents,
                addButtonProps: {
                    incidentType: 'insured',
                    buttonText: translator(messages.insuredButtonText, {
                        pageTypeName: _.upperFirst(PAGE_TYPE_NAME)
                    }),
                    tip: translator(messages.insuredButtonTip, {
                        pageType: PAGE_TYPE_NAME,
                        pageTypeName: _.upperFirst(PAGE_TYPE_NAME)
                    }),
                    disabled: isEditing
                }
            },
            vehicleInvolved: {
                label: translator(messages.vehicleInvolved, {
                    pageTypeName: PAGE_TYPE_NAME,
                }),
                disabled: !_.isEmpty(thirdPartyIncidents),
                value: involvedValue,
                onValueChange: (val) => updateInvolvedValue(val),
            },
            thirdPartyIncidentContainer: {
                visible: involvedValue,
                basePath: 'thirdPartyIncidents',
                tableData: thirdPartyIncidents,
                addButtonProps: {
                    incidentType: 'third_party',
                    buttonText: translator(messages.thirdButtonText, {
                        pageTypeName: _.upperFirst(PAGE_TYPE_NAME),
                    }),
                    tip: translator(messages.thirdButtonTip, {
                        pageTypeName: _.upperFirst(PAGE_TYPE_NAME),
                    }),
                    disabled: isEditing
                },
            }
        }),
        [claimVM, claimNumber, authHeader, syncWizardData, syncWizardDataSnapshot, resetWizardDataToSnapshot, syncVehicleData, saveIncident, VehicleService, LOB_NAME, PAGE_TYPE_NAME, initIncidentData, handleContactSelectionChange, availableDrivers, availablePassengers, isEditing, vehicles, vehicleIncidents, translator, thirdPartyIncidents, involvedValue]
    );
    const resolvers = {
        resolveComponentMap: {
            policyvehicle: PolicyVehicle,
            vehicleincident: VehicleIncident,
            contact: Contact,
        },
        resolveCallbackMap: {},
    };

    const readValue = (id, path) => {
        return readViewModelValue(
            metadata.pageContent,
            claimVM,
            id,
            path,
            overrides
        );
    };

    return (
        <WizardPage
            disableNext={!isComponentValid || !claimVM.aspects.valid || !claimVM.aspects.subtreeValid}
            skipWhen={initialValidation}
            cancelLabel={translator(fnolCommonMessages.fnolSaveandExit)}
            template={WizardPageTemplate}
            onNext={onPageNext}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={claimVM}
                overrideProps={overrides}
                onModelChange={updateWizardData}
                // onValueChange={writeValue}
                resolveValue={readValue}
                onValidationChange={onValidate}
                callbackMap={resolvers.resolveCallbackMap}
                classNameMap={resolvers.resolveClassNameMap}
                componentMap={resolvers.resolveComponentMap}
            />
        </WizardPage>
    );
}

FNOLPAVehiclesPage.propTypes = {
    viewModelService: PropTypes.shape({
        create: PropTypes.func,
    }).isRequired,
};

FNOLPAVehiclesPage.propTypes = wizardProps;
export default withRouter(FNOLPAVehiclesPage);
