import { setComponentMapOverrides } from '@jutro/legacy/uiconfig';

import FNOLRTWizard from './FNOLRTWizard.pe';
import FNOLRTVehiclesPage from './pages/Vehicles/FNOLRTVehiclesPage.pe';
import FNOLRTTypeOfIncidentPage from './pages/TypeOfIncident/FNOLRTTypeOfIncidentPage.pe';
import FNOLRTTypeOfRepairPage from './pages/TypeOfRepair/FNOLRTTypeOfRepairPage.pe';
import FNOLRTRepairFacilityPage from './pages/RepairFacility/FNOLRTRepairFacilityPage.pe';
import FNOLRTCollisionDetailsPage from './pages/Details/Collision/FNOLRTCollisionDetailsPage.pe';
import FNOLRTGlassDetailsPage from './pages/Details/Glass/FNOLRTGlassDetailsPage.pe';
import FNOLRTTheftDetailsPage from './pages/Details/Theft/FNOLRTTheftDetailsPage.pe';
import FNOLRTRepairFacilityMapPage from './pages/RepairFacilityMap/FNOLRepairFacilityMapPage.pe';
import FNOLRTLossTypePage from './pages/LossType/FNOLRTLossTypePage.pe';
import FNOLRTIncidentsPage from './pages/Incidents/FNOLRTIncidentsPage.pe';

setComponentMapOverrides(
    {
        FNOLRTWizard: { component: 'FNOLRTWizard' },
        FNOLRTTypeOfIncidentPage: { component: 'FNOLRTTypeOfIncidentPage' },
        FNOLRTVehiclesPage: { component: 'FNOLRTVehiclesPage' },
        FNOLRTTypeOfRepairPage: { component: 'FNOLRTTypeOfRepairPage' },
        FNOLRTRepairFacilityPage: { component: 'FNOLRTRepairFacilityPage' },
        FNOLRTCollisionDetailsPage: { component: 'FNOLRTCollisionDetailsPage' },
        FNOLRTGlassDetailsPage: { component: 'FNOLRTGlassDetailsPage' },
        FNOLRTTheftDetailsPage: { component: 'FNOLRTTheftDetailsPage' },
        FNOLRTRepairFacilityMapPage: { component: 'FNOLRTRepairFacilityMapPage' },
        FNOLRTLossTypePage: { component: 'FNOLRTLossTypePage' },
        FNOLRTIncidentsPage: { component: 'FNOLRTIncidentsPage' }
    },
    {
        FNOLRTWizard,
        FNOLRTTypeOfIncidentPage,
        FNOLRTVehiclesPage,
        FNOLRTTypeOfRepairPage,
        FNOLRTRepairFacilityPage,
        FNOLRTCollisionDetailsPage,
        FNOLRTGlassDetailsPage,
        FNOLRTTheftDetailsPage,
        FNOLRTRepairFacilityMapPage,
        FNOLRTLossTypePage,
        FNOLRTIncidentsPage
    }
);

export {
    FNOLRTWizard as default
};
