import { defineMessages } from 'react-intl';

export default defineMessages({
    createDraftErrorTitle: {
        id: 'wni.pe.fnol.cp.views.incidentdetails.common.Error creating incidents',
        defaultMessage: 'Error creating incidents'
    },
    createDraftErrorMessage: {
        id: 'wni.pe.fnol.cp.views.incidentdetails.common.An error occurred attempting to update incidents.',
        defaultMessage: 'An error occurred attempting to update incidents.'
    },
    Delete: {
        id: 'wni.pe.fnol.cp.views.incidentdetails.common-incidents.Delete',
        defaultMessage: "Delete"
    },
    AddProperty: {
        id: 'wni.pe.fnol.cp.views.incidentdetails.propertyIncidents.AddProperty',
        defaultMessage: "Add Property"
    },
    AddInjury: {
        id: 'wni.pe.fnol.cp.views.incidentdetails.injuryIncidents.AddInjury',
        defaultMessage: "Add Injury"
    },
    addPerson: {
        id: 'wni.pe.fnol.cp.views.incidentdetails.injuryIncidents.addPerson',
        defaultMessage: "-- Add Person --"
    },
    AddVehicle: {
        id: 'wni.pe.fnol.cp.views.incidentdetails.vehicleIncidents.AddVehicle',
        defaultMessage: "Add Vehicle"
    },
    AddNewVehicle: {
        id: 'wni.pe.fnol.cp.views.incidentdetails.vehicleIncidents.AddNewVehicle',
        defaultMessage: "-- Add Vehicle --"
    },
    AddNewProperty: {
        id: 'wni.pe.fnol.cp.views.incidentdetails.propertyIncidents.AddNewProperty',
        defaultMessage: "-- Add Property --"
    },
    addNewPerson: {
        id: 'wni.pe.fnol.cp.views.incidentdetails.propertyIncidents.addNewPerson',
        defaultMessage: "-- Add New Person --"
    },
    addNewCompany: {
        id: 'wni.pe.fnol.cp.views.incidentdetails.propertyIncidents.addNewCompany',
        defaultMessage: "-- Add New Company --"
    },
    removeIncidentTitle: {
        id: 'wni.pe.fnol.cp.views.incidentdetails.common.deleteTitle',
        defaultMessage: 'Want to Delete?',
    },
    removeIncident: {
        id: 'wni.pe.fnol.cp.views.incidentdetails.common.Are you sure you want to delete this record?',
        defaultMessage: 'Are you sure you want to delete this record?'
    },
    cancel: {
        id: 'wni.pe.fnol.cp.views.incidentdetails.common.cancel',
        defaultMessage: 'Cancel'
    },
    saveAndContinue: {
        id: 'wni.pe.fnol.cp.views.incidentdetails.common.save',
        defaultMessage: 'Save & Continue'
    },
    edit: {
        id: 'wni.pe.fnol.cp.views.cp-incident-deails.injury-incident.Edit',
        defaultMessage: 'Edit'
    },
});
