import React, { useCallback, useState, useEffect, useMemo } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import {
    WizardPage,
    wizardProps
} from '@xengage/gw-portals-wizard-react';
import { readViewModelValue } from '@xengage/gw-jutro-adapters-react';
import { Claim } from 'gw-capability-fnol-common-react';
import { withAuthenticationContext } from '@xengage/gw-digital-auth-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { withRouter } from 'react-router-dom';
import { useValidation } from '@xengage/gw-portals-validation-react';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';

import { SelectAddressComponent, LossLocationComponent } from 'wni-capability-fnol-ho-react';

import { FNOLService } from 'wni-capability-fnol';

import metadata from './FNOLCRIncidentDetailsPage.metadata.json5';


function FNOLCRIncidentDetailsPage(props) {
    const {
        wizardData: claimVM,
        updateWizardData,
        history: {
            location: { state = {} }
        },
        authHeader
    } = props;
    const { loadingMask: { setLoadingMask } } = useDependencies('loadingMask');
    // const { FNOLService } = useDependencies('FNOLService');
    const [pageData] = useState(state);
    const [showErrors, setShowErrors] = useState(false);
    const {
        onValidate,
        isComponentValid,
        initialValidation,
        registerInitialComponentValidation
    } = useValidation('FNOLCRIncidentDetailsPage');


    const lossSubCauseAvailableValues = _.get(claimVM, 'lossSubCause_Ext.aspects.availableValues', [])

    const predefinedLossLocations = _.get(claimVM.value, 'predefinedLossLocations');
    
    const initiallossLocationIndex = predefinedLossLocations.findIndex((location) => {
        return location.publicID === _.get(claimVM, 'value.lossLocation.publicID')
    })
    const [lossLocationIndex, updateLossLocationIndex] = useState(initiallossLocationIndex);
    const [countNewLocation, updateCountNewLocation] = useState(0);
    const primaryLocationID = _.get(claimVM, 'value.primaryLocation.publicID');
    const currentLossLocation = predefinedLossLocations[lossLocationIndex];
    const isLocationReadOnly = primaryLocationID === _.get(currentLossLocation, 'publicID');

    const setValidation = () => {
        setShowErrors(true)
        return false
    }

    const isClaimStatus = useCallback(() => {
        const { claimStatus } = pageData;
        return !_.isEmpty(claimStatus);
    }, [pageData]);

    useEffect(() => {
        registerInitialComponentValidation(isClaimStatus);
    }, [registerInitialComponentValidation, isClaimStatus]);

    const handleSaveClaimData = useCallback(() => {
        setLoadingMask(true);
        return FNOLService.saveFNOLDetails(claimVM.value, authHeader)
            .then((response) => {
                claimVM.value = new Claim(response);
                return claimVM;
            })
            .finally(() => {
                setLoadingMask(false);
            });
    }, [FNOLService, authHeader, claimVM, setLoadingMask]);

    const onNext = useCallback(async () => {
        setLoadingMask(true);
        const policyNumber = _.get(claimVM.value, 'policy.policyNumber');
        const isClaimNumberAvailable = _.get(claimVM.value, 'claimNumber');
        if (isClaimNumberAvailable) {
            return handleSaveClaimData();
        }
        // eslint-disable-next-line no-return-await
        return await FNOLService.getFNOLDetails(policyNumber, claimVM.value, authHeader)
            .then((response) => {
                const updatedClaimVM = _.clone(claimVM)
                updatedClaimVM.value = response
                updateWizardData(updatedClaimVM)
                return updatedClaimVM
            })
            .finally(() => {
                setLoadingMask(false);
            });
    }, [claimVM]);

    const onLocationChange = useCallback((value, path) => {
        const newClaimVM = _.clone(claimVM);
        _.set(newClaimVM, `value.predefinedLossLocations[${lossLocationIndex}].${path}`, value);
        if (_.isNil(_.get(newClaimVM, `value.predefinedLossLocations[${lossLocationIndex}].publicID`))) {
           _.set(newClaimVM, `value.predefinedLossLocations[${lossLocationIndex}].publicID`, `tempNew${countNewLocation}`)
           _.set(newClaimVM, `value.predefinedLossLocations[${lossLocationIndex}].country`, 'US')
        }
        _.set(newClaimVM, 'value.lossLocation', predefinedLossLocations[lossLocationIndex]);
        updateWizardData(newClaimVM);
    }, [claimVM, countNewLocation, lossLocationIndex, predefinedLossLocations, updateWizardData])
    
    const onSelectAddressChange = useCallback((value) => {
        const newClaimVM = _.clone(claimVM);
        if (value=== 'new') {
            updateLossLocationIndex(predefinedLossLocations.length);
            updateCountNewLocation(countNewLocation + 1);
        } else {
            const newLossLocationIndex = predefinedLossLocations.findIndex((location)=>location.publicID === value);
            updateLossLocationIndex(newLossLocationIndex);
            _.set(newClaimVM, 'value.lossLocation', predefinedLossLocations[newLossLocationIndex]);
        }
        updateWizardData(newClaimVM);
    }, [claimVM, countNewLocation, predefinedLossLocations, updateWizardData])

    const overrides = useMemo(() => ({
        '@field': {
            labelPosition: 'left',
            showOptional: true
        },
        selectAddress: {
            predefinedLossLocations: predefinedLossLocations,
            lossLocationIndex: lossLocationIndex,
            onSelectAddressChange: onSelectAddressChange,
            countNewLocation: countNewLocation
        },
        lossLocation: {
            predefinedLossLocations: predefinedLossLocations,
            lossLocationIndex: lossLocationIndex,
            onLocationChange: onLocationChange,
            isReadOnly: isLocationReadOnly,
            countNewLocation: countNewLocation,
            setComponentValidation: onValidate,
            showErrors: showErrors,
        },
        lossDetail: {
            visible: lossSubCauseAvailableValues.length > 0
        }
    }), [predefinedLossLocations, lossLocationIndex, onSelectAddressChange, countNewLocation, onLocationChange, isLocationReadOnly, onValidate, showErrors, lossSubCauseAvailableValues]);

    const readValue = (id, path) => {
        return readViewModelValue(metadata.pageContent, claimVM, id, path, overrides);
    };

    const resolvers = {
        resolveComponentMap: {
            SelectAddressComponent: SelectAddressComponent,
            losslocationcomponent: LossLocationComponent
        }
    };

    return (
        <WizardPage
            skipWhen={initialValidation}
            disableNext={!isComponentValid}
            onNext={isComponentValid ? onNext : setValidation }
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={claimVM}
                resolveValue={readValue}
                overrideProps={overrides}
                onValidationChange={onValidate}
                onModelChange={updateWizardData}
                componentMap={resolvers.resolveComponentMap}
                showErrors={showErrors}
            />
        </WizardPage>
    );
}

FNOLCRIncidentDetailsPage.propTypes = wizardProps;
FNOLCRIncidentDetailsPage.defaultProps = wizardProps.defaultProps;

FNOLCRIncidentDetailsPage.propTypes = {
    lobName: PropTypes.string,
    history: PropTypes.shape({
        push: PropTypes.func
    }).isRequired
};
FNOLCRIncidentDetailsPage.defaultProps = {
}
export default withRouter(withAuthenticationContext(FNOLCRIncidentDetailsPage));
